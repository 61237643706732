import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getLayoutData = basicSelector(state => {
  var _state$layoutData;
  return (_state$layoutData = state === null || state === void 0 ? void 0 : state.layoutData) !== null && _state$layoutData !== void 0 ? _state$layoutData : {};
});
export const getHasLayoutData = createSelector(getLayoutData, layoutData => Object.keys(layoutData).length > 0);
export const getIsLayoutBuiltIn = createSelector(getLayoutData, layoutData => layoutData.builtin);
export const getIsLayoutPurchased = createSelector(getLayoutData, layoutData => layoutData.purchased);
export const getIsLayoutReadOnly = createSelector(getLayoutData, layoutData => layoutData.isReadOnly);
export const getIsLayoutBuiltInWithBadData = createSelector([getLayoutData], layoutData => {
  return layoutData.authorAt === 0 && layoutData.id === 0 && layoutData.created === 0 && layoutData.updated === 0;
});
export const getLayoutId = createSelector([getLayoutData], layoutData => layoutData.id);
export const getLayoutBuiltInId = createSelector([getLayoutData], layoutData => layoutData.builtinId);
export const getHasMoreThanOnePageWithTemplate = createSelector([getLayoutData], layoutData => {
  var _layoutData$changeInf, _layoutData$changeInf2;
  return (_layoutData$changeInf = (_layoutData$changeInf2 = layoutData.changeInfo) === null || _layoutData$changeInf2 === void 0 ? void 0 : _layoutData$changeInf2.numContents) !== null && _layoutData$changeInf !== void 0 ? _layoutData$changeInf : 0 > 1;
});