import { BaseMockImmutableObject } from './BaseMockImmutableObject';
import { immerable, produce } from 'immer';
import cloneDeep from './cloneDeep';
// @ts-expect-error missing types
import { freeze } from 'immer';
export default function createMockTypedImmutableMap(mockImmutableName, serializationOptions) {
  class ImmutableObjectImplementation extends BaseMockImmutableObject {
    constructor(data) {
      super();
      this[immerable] = true;
      const jsonValue = Object.assign({}, data);
      const recordValue = serializationOptions ? serializationOptions.fromJSON(jsonValue) : jsonValue; // only when DeserializedType = JSONType, enforced using the different overrides for ImmutableObject

      Object.assign(this, recordValue);
      freeze(this, true);
    }
    toJSON() {
      if (serializationOptions) {
        return serializationOptions.toJSON(this); // this type is already enforced when ImmutableObject() is called
      } else {
        return cloneDeep(Object.assign({}, this)); // only when DeserializedType = JSONType, enforced using the different overrides for ImmutableObject;
      }
    }
    clear() {
      console.error('Clear called on BaseMockImmutableObject, which may not be type safe');
      return produce(this, draft => {
        // @ts-expect-error logging a type error here
        Object.assign(draft, new ImmutableObjectImplementation({}));
      });
    }
    getBaseName() {
      return mockImmutableName;
    }
    logUsage(methodName) {
      super.logUsage(methodName);
    }
  }
  return ImmutableObjectImplementation;
}