import { makeHublModuleElementManager } from 'tinymce-plugins/hsmoduleinsertion/utils/hubl';
import { captureMessage } from 'ContentEditorUI/lib/exceptions';
// Get the nested modules that have hydrated data in `widgetsInRichText`
export function getOrderedNestedModulesInRichTextImmutable(html, widgetsInRichText) {
  const hublModuleElementManager = makeHublModuleElementManager(html);
  const nestedModuleNames = new Set();
  const nestedModules = hublModuleElementManager.getAll().map(nestedModuleElement => {
    var _nestedModuleElement$;
    const moduleName = nestedModuleElement === null || nestedModuleElement === void 0 || (_nestedModuleElement$ = nestedModuleElement.info) === null || _nestedModuleElement$ === void 0 ? void 0 : _nestedModuleElement$.name;
    if (nestedModuleNames.has(moduleName)) {
      const message = `[ERROR] Duplicate module found in rich text`;
      captureMessage(message, {
        extra: {
          moduleName
        }
      });
      console.error(message, moduleName);
    }
    nestedModuleNames.add(moduleName);
    return widgetsInRichText[moduleName];
  }).filter(nestedModule => {
    if (!nestedModule) {
      const message = `[ERROR] Couldn't find module data in widgetInRichText`;
      captureMessage(message);
      console.error(message);
    }
    return Boolean(nestedModule);
  });
  return nestedModules;
}

// Get the nested modules that have hydrated data in `widgetsInRichText`
export function getOrderedNestedModulesInRichTextImmer(html, widgetsInRichText) {
  const hublModuleElementManager = makeHublModuleElementManager(html);
  const nestedModuleNames = new Set();
  const nestedModules = hublModuleElementManager.getAll().map(nestedModuleElement => {
    var _nestedModuleElement$2;
    const moduleName = nestedModuleElement === null || nestedModuleElement === void 0 || (_nestedModuleElement$2 = nestedModuleElement.info) === null || _nestedModuleElement$2 === void 0 ? void 0 : _nestedModuleElement$2.name;
    if (nestedModuleNames.has(moduleName)) {
      const message = `[ERROR] Duplicate module found in rich text`;
      captureMessage(message, {
        extra: {
          moduleName
        }
      });
      console.error(message, moduleName);
    }
    nestedModuleNames.add(moduleName);
    return widgetsInRichText[moduleName];
  }).filter(nestedModule => {
    if (!nestedModule) {
      const message = `[ERROR] Couldn't find module data in widgetInRichText`;
      captureMessage(message);
      console.error(message);
    }
    return Boolean(nestedModule);
  });
  return nestedModules;
}