/* hs-eslint ignored failing-rules */
/* eslint-disable no-bitwise */

import Immutable from 'immutable';
import { clone, pick, map } from 'underscore';
import { BODY_ID } from 'ContentEditorUI/lib/widgetEdit/constants';
import { FORM_DEFAULT_SUB_TYPE_VALUE, FORM_BUTTON_SUB_TYPE_VALUE, FORM_FIELDS_SUB_TYPE_VALUE, FORM_LABEL_SUB_TYPE_VALUE, FORM_BUTTON_ALIGNMENT_SUB_TYPE_VALUE, FORM_RICH_TEXT_SUB_TYPE_VALUE, FORM_INPUT_ERROR_SUB_TYPE_VALUE, FORM_FIELD_ALIGNMENT_SUB_TYPE_VALUE, FORM_HELP_TEXT_SUB_TYPE_VALUE, fontStyleFields
// @ts-expect-error not typed yet
} from 'ContentEditorUI/components/sidebar/styles/StyleConstants';
// @ts-expect-error not typed yet
import { convertMarginPaddingBreakpointStylesToCssFormat } from 'layout-dnd-utils/layoutTreeStyles/helpers';
import { isImmutable, maybeToJS, getImmutableOrPlain } from '../../utils/dataHelpers';
import cloneDeep from '../../utils/cloneDeep';
import { MockImmutableLayoutSectionModule } from '../../redux/selectors/moduleSelectorHelpers';

// Extracted and ported from the Backbone widget model's getSelector method
const getModuleSelector = module => {
  // a widget's selector in rendered content.
  // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
  if (getImmutableOrPlain(module, 'parentWidget') && getImmutableOrPlain(module, 'selector')) {
    // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
    const selectorParts = getImmutableOrPlain(module, 'selector').split(',');
    const selectors = [];
    for (const selectorPart of selectorParts) {
      const parentWidget = getImmutableOrPlain(module, 'parentWidget');
      selectors.push(
      // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
      `#hs_cos_wrapper_${getImmutableOrPlain(parentWidget, 'name')} ${selectorPart}`);
    }
    return selectors.join(', ');
    // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
  } else if (getImmutableOrPlain(module, 'selector')) {
    // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
    return getImmutableOrPlain(module, 'selector');
  } else {
    return `#hs_cos_wrapper_${getImmutableOrPlain(module, 'name')}`;
  }
};
function cssForModule(module, type, options = {}) {
  const {
    isChild
  } = options;
  const mainSelector = getModuleSelector(module);
  // TODO: CR-immer: fix the downstream type changes that will have to be made once this
  // returns a ModuleCss
  let css = maybeToJS(getImmutableOrPlain(module, 'css'));
  if (!isImmutable(module)) {
    css = cloneDeep(css);
  }
  if (css['background-color'] && css['background-color'] !== 'transparent') {
    css['background-image'] = 'none';
  }
  if (css['box-shadow']) {
    css['box-shadow'] = css['box-shadow'].replace(/\|/g, ' ');
  }
  if (css['text-shadow']) {
    css['text-shadow'] = css['text-shadow'].replace(/\|/g, ' ');
  }
  const selectorMap = {};
  selectorMap[''] = css;
  if (type === 'menu' || type === 'simple_menu') {
    selectorMap[' .hs-menu-wrapper > ul'] = clone(css);
    selectorMap[' li.hs-menu-item a'] = pick(css, ['font-weight', 'color', 'font-size', 'line-height', 'text-align', 'font-family', 'background-color']);
    selectorMap[' li.hs-menu-item a:link'] = pick(css, ['font-weight', 'color', 'font-size', 'line-height', 'text-align', 'font-family', 'background-color']);
    delete selectorMap[''];
  }
  let inheritableElements = ['p', 'li', 'span', 'h1', 'h2', 'h3', 'h3', 'h4', 'h5', 'h6', 'label'];
  if (type === 'form' && isChild && getImmutableOrPlain(module, 'label') !== 'label') {
    inheritableElements = [];
  }
  let inheritableProperties = [];
  if (type && ~['rich_text', 'richtext'].indexOf(type)) {
    inheritableProperties = ['font-family', 'color', 'text-align'];
  } else {
    inheritableProperties = ['font-family', 'color', 'line-height', 'font-size', 'font-weight'];
  }
  let inheritableModuleProps = pick(maybeToJS(getImmutableOrPlain(module, 'css')), inheritableProperties);
  inheritableModuleProps = map(inheritableModuleProps, (val, prop) => {
    return `${prop}: ${val} !important`;
  });
  const inheritableModuleStr = inheritableModuleProps.join('; ');
  const inheritSelectors = map(inheritableElements, tagName => `${mainSelector} ${tagName}`);
  const inheritCssStr = `${inheritSelectors.join(', ')} { ${inheritableModuleStr} } `;
  let cssStr = '';
  Object.keys(selectorMap).forEach(key => {
    const propertiesStr = map(
    // @ts-expect-error TODO CR-immer
    selectorMap[key], (val, prop) => `${prop}: ${val} !important`);
    const propertyStr = propertiesStr.join('; ');
    cssStr += `\n${mainSelector} ${key} { ${propertyStr} }`;
  });
  if (inheritSelectors.length > 0 && inheritableModuleStr) {
    cssStr += `\n ${inheritCssStr}\n`;
  }
  return cssStr;
}
const isNotEmptyMap = cssMap => {
  if (!cssMap) {
    return false;
  }
  if (isImmutable(cssMap)) {
    return cssMap.size > 0;
  }
  return typeof cssMap === 'object' && Object.keys(cssMap).length > 0;
};

// This data mapping subtypes to CSS selectors for the renderer is in this config:
// https://private.hubteam.com/config/com.hubspot.content.rendering/ContentRenderingConfig/EDITOR_CSS_SELECTOR_MAP/JSON_OBJECT
const FORM_INPUT_SUBTYPE_SELECTOR = 'input[type="text"], input[type="email"], textarea, input[type="number"], select, input[type="file"], input[type="tel"], input[type="date"]';
const FORM_BUTTON_SUBTYPE_SELECTOR = 'input[type="submit"], .hs-button';
const FORM_LABEL_SUBTYPE_SELECTOR = 'label:not(.hs-error-msg)';
const FORM_BUTTON_ALIGNMENT_SUBTYPE_SELECTOR = '.hs-submit';
const FORM_RICH_TEXT_SUBTYPE_SELECTOR = '.hs-richtext, .hs-richtext p, .form-title, h3, .submitted-message';
const FORM_INPUT_ERROR_SUBTYPE_SELECTOR = '.hs-error-msg, .hs_error_rollup .hs-error-msgs .hs-main-font-element';
const FORM_FIELD_ALIGNMENT_SUBTYPE_SELECTOR = '.hs-form-field';
const FORM_HELP_TEXT_SUBTYPE_SELECTOR = '.hs-field-desc';
export function getModuleStylesCssString(modulesAndTypes, bodyModule) {
  let cssStr = '';
  modulesAndTypes.forEach(({
    module,
    moduleType
  }) => {
    if (isNotEmptyMap(module.get('css'))) {
      cssStr += `${cssForModule(module, moduleType)}\n`;
    }
    if (isNotEmptyMap(module.get('child_css'))) {
      module.get('child_css').keySeq().forEach(subfield => {
        let selector = '';
        if (subfield === 'input') {
          selector = FORM_INPUT_SUBTYPE_SELECTOR;
        }
        if (subfield === 'button') {
          selector = FORM_BUTTON_SUBTYPE_SELECTOR;
        }
        if (subfield === 'buttonAlignment') {
          selector = FORM_BUTTON_ALIGNMENT_SUBTYPE_SELECTOR;
        }
        if (subfield === 'label') {
          selector = FORM_LABEL_SUBTYPE_SELECTOR;
        }
        if (subfield === FORM_RICH_TEXT_SUB_TYPE_VALUE) {
          selector = FORM_RICH_TEXT_SUBTYPE_SELECTOR;
        }
        if (subfield === FORM_INPUT_ERROR_SUB_TYPE_VALUE) {
          selector = FORM_INPUT_ERROR_SUBTYPE_SELECTOR;
        }
        if (subfield === FORM_FIELD_ALIGNMENT_SUB_TYPE_VALUE) {
          selector = FORM_FIELD_ALIGNMENT_SUBTYPE_SELECTOR;
        }
        if (subfield === FORM_HELP_TEXT_SUB_TYPE_VALUE) {
          selector = FORM_HELP_TEXT_SUBTYPE_SELECTOR;
        }

        // @ts-expect-error not changing immutable logic
        const childModule = new Immutable.Map({
          name: `${module.get('name')}__${subfield}`,
          selector,
          css: module.getIn(['child_css', subfield]),
          label: subfield,
          parentWidget: module,
          type: 'fake'
        });
        cssStr += `${cssForModule(childModule, moduleType, {
          isChild: true
        })}\n`;
      });
    }
  });
  if (isNotEmptyMap(bodyModule.get('css'))) {
    cssStr += cssForModule(bodyModule);
  }
  return cssStr;
}
export function getModuleStylesCssStringImmer(modulesAndTypes, bodyModule) {
  let cssStr = '';
  modulesAndTypes.forEach(({
    module,
    moduleType
  }) => {
    if (isNotEmptyMap(module.css)) {
      cssStr += `${cssForModule(module, moduleType)}\n`;
    }
    if (isNotEmptyMap(module.child_css)) {
      Object.keys(module.child_css).forEach(subfield => {
        var _module$child_css;
        let selector = '';
        if (subfield === 'input') {
          selector = FORM_INPUT_SUBTYPE_SELECTOR;
        }
        if (subfield === 'button') {
          selector = FORM_BUTTON_SUBTYPE_SELECTOR;
        }
        if (subfield === 'buttonAlignment') {
          selector = FORM_BUTTON_ALIGNMENT_SUBTYPE_SELECTOR;
        }
        if (subfield === 'label') {
          selector = FORM_LABEL_SUBTYPE_SELECTOR;
        }
        if (subfield === FORM_RICH_TEXT_SUB_TYPE_VALUE) {
          selector = FORM_RICH_TEXT_SUBTYPE_SELECTOR;
        }
        if (subfield === FORM_INPUT_ERROR_SUB_TYPE_VALUE) {
          selector = FORM_INPUT_ERROR_SUBTYPE_SELECTOR;
        }
        if (subfield === FORM_FIELD_ALIGNMENT_SUB_TYPE_VALUE) {
          selector = FORM_FIELD_ALIGNMENT_SUBTYPE_SELECTOR;
        }
        if (subfield === FORM_HELP_TEXT_SUB_TYPE_VALUE) {
          selector = FORM_HELP_TEXT_SUBTYPE_SELECTOR;
        }
        const childModule = new MockImmutableLayoutSectionModule({
          name: `${module.name}__${subfield}`,
          // @ts-expect-error I have never seen a "selector" property on a module before
          selector,
          // @ts-expect-error css types are still unfinished
          css: (_module$child_css = module.child_css) === null || _module$child_css === void 0 ? void 0 : _module$child_css[subfield],
          label: subfield,
          parentWidget: module,
          type: 'fake'
        });

        // @ts-expect-error technically this needs more properties
        cssStr += `${cssForModule(childModule, moduleType, {
          isChild: true
        })}\n`;
      });
    }
  });
  if (isNotEmptyMap(bodyModule.css)) {
    cssStr += cssForModule(bodyModule);
  }
  return cssStr;
}
export function getNonModuleStyles(modulesAndTypes, bodyModule) {
  const css = {};
  modulesAndTypes.forEach(({
    module,
    moduleType
  }) => {
    // @ts-expect-error TODO CR-immer
    if (!module.get('overrideable') && !module.get('parentWidget')) {
      if (isNotEmptyMap(module.get('css'))) {
        const moduleCSS = module.get('css').merge({
          _type: moduleType
        });
        // @ts-expect-error TODO CR-immer
        css[module.get('id')] = moduleCSS.toJS();
      }
    }
  });
  if (isNotEmptyMap(bodyModule.get('css'))) {
    const bodyCSS = bodyModule.get('css').merge({
      _type: 'fake'
    });
    css[BODY_ID] = bodyCSS.toJS();
  }
  return css;
}
export function getNonModuleStylesImmer(modulesAndTypes, bodyModule) {
  const css = {};
  modulesAndTypes.forEach(({
    module,
    moduleType
  }) => {
    // @ts-expect-error TODO CR-immer: finalize central module type
    if (!module.overrideable && !module.parentWidget) {
      if (isNotEmptyMap(module.css)) {
        const moduleCSS = Object.assign({}, module.css, {
          _type: moduleType
        });
        // @ts-expect-error TODO CR-immer
        css[module.id] = moduleCSS;
      }
    }
  });
  if (isNotEmptyMap(bodyModule.css)) {
    const bodyCSS = Object.assign({}, bodyModule.css, {
      _type: 'fake'
    });
    css[BODY_ID] = bodyCSS;
  }
  return css;
}

// Shared functions for older and newer styled editors

export const getTargetedCssHelper = (css, childCss, childModuleName = 'default') => {
  if (childModuleName === 'default') {
    return css || {};
  } else if (childCss) {
    return childCss[childModuleName] || {};
  }
  return {};
};
export const getActualStyleHelper = (attr, styles) => {
  const value = styles[attr];
  return typeof value !== 'undefined' ? value : '';
};
const getSelectorForModule = module => {
  // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
  if (module.get('parentWidget') && module.get('selector')) {
    return (
      // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
      module
      // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
      .get('selector')
      // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
      .split(',')
      // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
      .map(selectorPart => {
        // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
        return `#hs_cos_wrapper_${module
        // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
        .get('parentWidget')
        // @ts-expect-error TODO CR-immer: I have not seen these properties on a module before
        .get('name')} ${selectorPart}`;
      }).join(', ')
    );
  }
  return `#hs_cos_wrapper_${module.get('name')}`;
};
export const getSelectorForType = (module, type = 'default') => {
  if (module.get('name') === 'el__body') {
    return 'body';
  }
  let selector = getSelectorForModule(module);
  if (type === 'input') {
    const types = ['input[type="text"]', 'input[type="email"]', 'textarea', 'input[type="number"]', 'select', 'input[type="file"]', 'input[type="tel"]'];
    selector = map(types, subtype => `${selector} ${subtype}`).join(', ');
  } else if (type === 'button') {
    const types = ['button', 'input[type="submit"]', '.hs-button'];
    selector = map(types, subtype => `${selector} ${subtype}`).join(', ');
  } else if (type === 'label') {
    selector = `${selector} ${FORM_LABEL_SUBTYPE_SELECTOR}`;
  } else if (type === 'buttonAlignment') {
    selector = `${selector} ${FORM_BUTTON_ALIGNMENT_SUBTYPE_SELECTOR}`;
  } else if (type === FORM_RICH_TEXT_SUB_TYPE_VALUE) {
    const types = FORM_RICH_TEXT_SUBTYPE_SELECTOR.split(',');
    selector = map(types, subtype => `${selector} ${subtype}`).join(', ');
  } else if (type === FORM_INPUT_ERROR_SUB_TYPE_VALUE) {
    const types = FORM_INPUT_ERROR_SUBTYPE_SELECTOR.split(',');
    selector = map(types, subtype => `${selector} ${subtype}`).join(', ');
  } else if (type === FORM_FIELD_ALIGNMENT_SUB_TYPE_VALUE) {
    selector = `${selector} ${FORM_FIELD_ALIGNMENT_SUBTYPE_SELECTOR}`;
  } else if (type === FORM_HELP_TEXT_SUB_TYPE_VALUE) {
    selector = `${selector} ${FORM_HELP_TEXT_SUBTYPE_SELECTOR}`;
  }
  return selector;
};
const isMarginOrPaddingStyle = styleKey => styleKey.indexOf('margin') === 0 || styleKey.indexOf('padding') === 0;
const isNotMarginOrPaddingStyle = styleKey => !isMarginOrPaddingStyle(styleKey);
export const hasAnyLegacyRichTextStyles = (type, css) => {
  if (type === 'rich_text') {
    const nonMarginOrPaddingStyles = Object.keys(css).filter(isNotMarginOrPaddingStyle);
    return nonMarginOrPaddingStyles.length > 0;
  }
  return false;
};
export const getAllCssTypesForChildModuleName = childModuleName => {
  switch (childModuleName) {
    case FORM_DEFAULT_SUB_TYPE_VALUE:
      return [FORM_DEFAULT_SUB_TYPE_VALUE, FORM_RICH_TEXT_SUB_TYPE_VALUE];
    case FORM_BUTTON_SUB_TYPE_VALUE:
      return [FORM_BUTTON_SUB_TYPE_VALUE, FORM_BUTTON_ALIGNMENT_SUB_TYPE_VALUE];
    case FORM_FIELDS_SUB_TYPE_VALUE:
      return [FORM_LABEL_SUB_TYPE_VALUE, FORM_FIELDS_SUB_TYPE_VALUE, FORM_INPUT_ERROR_SUB_TYPE_VALUE, FORM_FIELD_ALIGNMENT_SUB_TYPE_VALUE, FORM_HELP_TEXT_SUB_TYPE_VALUE];
    default:
      return [childModuleName];
  }
};
export const getHasAnyNonLayoutCssHelper = (css, childCss, childModuleName) => {
  const subTypesToCheck = getAllCssTypesForChildModuleName(childModuleName);
  for (let i = 0; i < subTypesToCheck.length; i++) {
    const stylesForSubType = getTargetedCssHelper(css, childCss, subTypesToCheck[i]);
    if (Object.keys(stylesForSubType).length > 0) {
      return true;
    }
  }
  return false;
};
const mergeNewStylesIntoExisting = (newStyles, css, childCss, childModuleName = 'default') => {
  const newCss = Object.assign({}, getTargetedCssHelper(css, childCss, childModuleName));
  Object.keys(newStyles).forEach(attr => {
    const val = newStyles[attr];
    if (val === null) {
      // @ts-expect-error TODO CR-immer
      delete newCss[attr];
    } else {
      // @ts-expect-error TODO CR-immer
      newCss[attr] = val;
    }
  });
  return newCss;
};
export const newCssObjectToSetHelper = (newStyles, css, childCss, childModuleName = 'default') => {
  const newCss = mergeNewStylesIntoExisting(newStyles, css, childCss, childModuleName);
  const result = {};
  if (childModuleName === 'default') {
    result.css = newCss;
  } else {
    result.child_css = Object.assign({}, childCss);
    result.child_css[childModuleName] = newCss;
  }
  return result;
};

// Note, this does _not_ clear css for all children, only the currently selected child
//
// TODO, try and fix the color style fields that don't update after all styles are reset???
export const getStylesToClearHelper = (css, childCss, childModuleName) => {
  const stylesToClear = {};
  const targetedCss = getTargetedCssHelper(css, childCss, childModuleName);

  // @ts-expect-error TODO CR-immer
  Object.keys(targetedCss).forEach(attr => stylesToClear[attr] = null);
  return stylesToClear;
};
export const getLegacyRichTextStylesToClearHelper = css => {
  const targetedCss = getTargetedCssHelper(css);
  const stylesToClear = {};
  Object.keys(targetedCss).filter(isNotMarginOrPaddingStyle)
  // @ts-expect-error TODO CR-immer
  .forEach(attr => stylesToClear[attr] = null);
  return stylesToClear;
};
const removeTrailingPxUnit = str => {
  return str.replace(/px/, '');
};
const prepareMarginAndPaddingDataHelper = (targetedCss, computedStyles) => {
  const dirs = ['left', 'right', 'bottom', 'top'];
  const marginData = {};
  const paddingData = {};
  dirs.forEach(dir => {
    marginData[dir] = {
      value: getActualStyleHelper(`margin-${dir}`, targetedCss),
      placeholder: removeTrailingPxUnit(getActualStyleHelper(`margin-${dir}`, computedStyles))
    };
    paddingData[dir] = {
      value: getActualStyleHelper(`padding-${dir}`, targetedCss),
      placeholder: removeTrailingPxUnit(getActualStyleHelper(`padding-${dir}`, computedStyles))
    };
  });
  return {
    marginData,
    paddingData
  };
};
export const prepareMarginAndPaddingData = (css, computedStyles, childCss, childModuleName) => {
  const targetedCss = getTargetedCssHelper(css, childCss, childModuleName);
  return prepareMarginAndPaddingDataHelper(targetedCss, computedStyles);
};
export const prepareMarginAndPaddingDataForBreakpoint = (styles, computedStyles) => {
  const targetedCss = convertMarginPaddingBreakpointStylesToCssFormat(styles);
  return prepareMarginAndPaddingDataHelper(targetedCss, computedStyles);
};
export const getSupportedTypographyStyles = subtype => {
  switch (subtype) {
    case FORM_FIELD_ALIGNMENT_SUB_TYPE_VALUE:
      return ['text-align'];
    case FORM_BUTTON_ALIGNMENT_SUB_TYPE_VALUE:
      return ['text-align'];
    case FORM_HELP_TEXT_SUB_TYPE_VALUE:
    case FORM_INPUT_ERROR_SUB_TYPE_VALUE:
    case FORM_LABEL_SUB_TYPE_VALUE:
      return ['font-family', 'font-size', 'color', 'font-weight', 'font-style', 'font-variant', 'line-height'];
    default:
      return Object.keys(fontStyleFields);
  }
};
const dependentFieldHasText = (group, condition) => {
  if (group && group.dependentFieldFilters) {
    const dependentFields = group.dependentFieldFilters;
    // @ts-expect-error TODO CR-immer
    for (let x = 0; x < dependentFields.length; x++) {
      // @ts-expect-error TODO CR-immer
      const field = dependentFields[x].dependentFormField;
      if (condition(field)) {
        return true;
      }
    }
    return false;
  }
  return false;
};
export const doesFormContainInputErrorText = form => {
  const fieldGroups = form.formFieldGroups;
  const hasInputErrorText = group => group && (group.validation && group.validation.message || group.required);
  for (let i = 0; i < fieldGroups.length; i++) {
    const group = fieldGroups[i].fields[0];
    if (hasInputErrorText(group) || dependentFieldHasText(group, hasInputErrorText)) {
      return true;
    }
  }
  return false;
};
export const doesFormContainsHelpText = form => {
  const fieldGroups = form.formFieldGroups;
  const hasHelpText = group => group && group.description;
  for (let i = 0; i < fieldGroups.length; i++) {
    const group = fieldGroups[i].fields[0];
    if (hasHelpText(group) || dependentFieldHasText(group, hasHelpText)) {
      return true;
    }
  }
  return false;
};
export const getDeprecatedFlags = (module, defaultValue = []) => {
  const type = getImmutableOrPlain(module, 'type');
  if (type === 'fake') {
    const contentCss = getImmutableOrPlain(module, 'css') ? maybeToJS(getImmutableOrPlain(module, 'css')) : {};
    return contentCss.deprecated_flags || defaultValue;
  } else {
    const body = getImmutableOrPlain(module, 'body') ? maybeToJS(getImmutableOrPlain(module, 'body')) : {};
    return body.deprecated_flags || defaultValue;
  }
};

// While normally having any value for a deprecated style would mean it has a
// deprecated style, 'font-weight' can have a value that is *not* deprecated,
// so this function is a null check that also handles special cases
export const hasDeprecatedStyleValue = (style, targetedCss) => {
  const value = getActualStyleHelper(style, targetedCss);
  if (style === 'font-weight') {
    return !!value && value !== 'bold';
  }
  return !!value;
};
export const getDeprecatedStyleModalBodyKey = (style, isActivelySet = false) => {
  if (style === 'opacity') {
    return isActivelySet ? 'groupedStylesSidebar.sections.deprecated.delete.modal.body.active.opacity' : 'groupedStylesSidebar.sections.deprecated.delete.modal.body.cleared.opacity';
  } else if (style === 'font-variant' || style === 'font-weight') {
    return isActivelySet ? 'groupedStylesSidebar.sections.deprecated.delete.modal.body.active.textStyles' : 'groupedStylesSidebar.sections.deprecated.delete.modal.body.cleared.textStyles';
  }
  throw new Error(`Attempted to get deprecated style delete modal body text for unknown style ${style}`);
};