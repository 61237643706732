import { breakpointStyleImplementationsForTreeNode, computeTreeNodeDataToDesiredDomStateData, computeModuleDataToDesiredDomStateData, getLayoutStyleCssString
// @ts-expect-error not typed yet
} from 'layout-dnd-utils/layoutTreeStyles/calculateLayoutStyleData';
import { createSelector } from 'reselect';
import { getAllLayoutSectionTreesAsMap, getAllStaticAndFlexColumnModules, getFakeBodyModule, getAllNonFakeBodyModules, getDefaultTypeHelper, getCellOrRowById, getModuleById, getIsModuleEditable, getIsDefaultModule, getIsCMv2Module, getIsFakeBodyOrTitleModule, getAllV2ModuleDefinitionIdsWithStylesOnPage, getIsGlobalModuleGroupOrPartial, isFakeBodyModuleName, getIsCustomCmv2Module, getIsModuleInLayoutSection, getModulesByNameFromPostBody } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getModuleStylesCssString, getModuleStylesCssStringImmer, getNonModuleStyles, getNonModuleStylesImmer } from 'ContentEditorUI/lib/widgetEdit/StyleEditorUtils';
import { getBackgroundColorFromLayoutStyles, getBackgroundImageFromLayoutStyles, getBackgroundGradientFromLayoutStyles, getHiddenFromLayoutStyles, getMarginFromLayoutStyles, getPaddingFromLayoutStyles, getSectionMaxWidthFromLayoutStyles, getSectionForceFullWidthFromLayoutStyles, getFlexboxPositioningFromLayoutStyles, getBackgroundLayersFromLayoutStyles
// @ts-expect-error not typed yet
} from 'layout-dnd-utils/layoutTreeStyles/helpers';
import { getModuleIdsByBuiltinType, getBuiltInTypesByModuleId, getAllModuleSchemasAsArray, getSchemaForModuleHelper } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
// @ts-expect-error not typed yet
import { calculateExistingVerticalAlignmentForRow } from 'layout-dnd-utils/layoutTreeStyles/implementations/verticalAlignment';
import { getSelectedModuleInTree, getSelectedModule } from 'ContentEditorUI/redux/selectors/selectionSelectors';
import { getActiveBreakpointName, getBreakpointsByName, getShouldUseResponsiveBreakpoints } from 'ContentEditorUI/redux/selectors/styleBreakpointsSelectors';
import { getCategoryId, getIsBlogPost } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { basicSelectorWithStats, makeGatedSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsUngatedForBackgroundLayers, getIsUngatedForBackgroundLayersGenerateStyles, getIsUngatedForImmerModuleReducer } from './authSelectors';
import { isNullOrUndefined } from 'ContentUtils/helpers/ObjectHelpers';

// @ts-expect-error not typed yet

import { getImmutableOrPlain, isImmutable, hasOwn } from '../../utils/dataHelpers';
const nodeExtractor = (__state, node) => node;
const getLayoutStylesForNode = createSelector([nodeExtractor], node => !isNullOrUndefined(node) ? node.getLayoutStyleData() : null);
export const getAllLayoutTreeNodesWithLayoutStyles = createSelector(getAllLayoutSectionTreesAsMap, layoutSectionTrees => {
  return Object.values(layoutSectionTrees).flatMap(tree => tree.allCellsAndRows()).filter(node => node.getLayoutStyleData());
});
export const getLayoutStylesHiddenForNode = createSelector([getLayoutStylesForNode, getActiveBreakpointName], (styleData, activeBreakpoint) => {
  return getHiddenFromLayoutStyles(styleData, activeBreakpoint);
});
export const makeGetIsLayoutTreeNodeHiddenForId = () => createSelector(getActiveBreakpointName, getCellOrRowById, (activeBreakpoint, node) => {
  if (node && node.getLayoutStyleData()) {
    return getHiddenFromLayoutStyles(node.getLayoutStyleData(), activeBreakpoint);
  } else {
    return null;
  }
});
export const getComputedLayoutStyleDataToDesiredDomStateData = createSelector([getBreakpointsByName, getAllLayoutTreeNodesWithLayoutStyles, getIsUngatedForBackgroundLayers, getIsUngatedForBackgroundLayersGenerateStyles], (breakpoints, nodesWithLayoutStyles, isUngatedForBackgroundLayers, isUngatedForBackgroundLayersGenerateStyles) => {
  const styleImplementations = breakpointStyleImplementationsForTreeNode;

  // Turn off computing background layers if gated for background layers
  if (!isUngatedForBackgroundLayersGenerateStyles && !isUngatedForBackgroundLayers) {
    delete styleImplementations['backgroundLayers'];
  }
  return computeTreeNodeDataToDesiredDomStateData(nodesWithLayoutStyles, breakpoints, styleImplementations);
});
export const getComputedModuleStyleDataToDesiredDomStateData = createSelector([getBreakpointsByName, getAllStaticAndFlexColumnModules], (breakpoints, modules) => {
  return computeModuleDataToDesiredDomStateData(Object.values(modules), breakpoints);
});
export const getComputedModuleAndLayoutStyleCssString = createSelector(getComputedModuleStyleDataToDesiredDomStateData, getComputedLayoutStyleDataToDesiredDomStateData, (moduleStateData, layoutStyleData) => {
  return getLayoutStyleCssString(Object.assign({}, layoutStyleData, moduleStateData));
});
// TODO, another good candiate where mulit-level selector memoization will make a significant impact
export const getAllNonFakeBodyModulesAndTheirTypes = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getAllNonFakeBodyModules.gated, getModuleIdsByBuiltinType, getBuiltInTypesByModuleId, getAllModuleSchemasAsArray, getCategoryId, (allNonBodyModules, moduleIdsByBuiltinType, builtInTypesByModuleId, allModulesSchemasArray, categoryId) => {
  const modulesWithTypes = [];
  Object.values(allNonBodyModules).forEach(module => {
    const schemaForModule = getSchemaForModuleHelper(module, allModulesSchemasArray, moduleIdsByBuiltinType, categoryId);
    const moduleType = getDefaultTypeHelper(module, schemaForModule, builtInTypesByModuleId);
    modulesWithTypes.push({
      module,
      moduleType
    });
  });
  return modulesWithTypes;
}), createSelector(getAllNonFakeBodyModules.ungated, getModuleIdsByBuiltinType, getBuiltInTypesByModuleId, getAllModuleSchemasAsArray, getCategoryId, (allNonBodyModules, moduleIdsByBuiltinType, builtInTypesByModuleId, allModulesSchemasArray, categoryId) => {
  const modulesWithTypes = [];
  Object.values(allNonBodyModules).forEach(module => {
    const schemaForModule = getSchemaForModuleHelper(module, allModulesSchemasArray, moduleIdsByBuiltinType, categoryId);
    const moduleType = getDefaultTypeHelper(module, schemaForModule, builtInTypesByModuleId);
    modulesWithTypes.push({
      module: module,
      moduleType
    });
  });
  return modulesWithTypes;
}));
export const getComputedModuleStyleCssString = createSelector(getAllNonFakeBodyModulesAndTheirTypes, getFakeBodyModule, (modulesAndTypes, reduxBodyModule) => {
  if (isImmutable(reduxBodyModule)) {
    // @ts-expect-error We know that modulesAndTypes must hold immutables
    return getModuleStylesCssString(modulesAndTypes, reduxBodyModule);
  }
  // @ts-expect-error We know that modulesAndTypes must hold POJOs
  return getModuleStylesCssStringImmer(modulesAndTypes, reduxBodyModule);
});
export const getComputedNonModuleStyleCssString = createSelector(getAllNonFakeBodyModulesAndTheirTypes, getFakeBodyModule, (modulesAndTypes, reduxBodyModule) => {
  if (isImmutable(reduxBodyModule)) {
    // @ts-expect-error We know that modulesAndTypes must hold immutables
    return getNonModuleStyles(modulesAndTypes, reduxBodyModule);
  }
  // @ts-expect-error We know that modulesAndTypes must hold POJOs
  return getNonModuleStylesImmer(modulesAndTypes, reduxBodyModule);
});
export const getComputedCombinedEditorStyles = createSelector(getComputedModuleAndLayoutStyleCssString, getComputedModuleStyleCssString, (computedModuleAndLayoutCssStrings, moduleCssString) => {
  return `${moduleCssString} \n${computedModuleAndLayoutCssStrings}`;
});
export const getSelectModuleHasAnyLayoutStyles = createSelector([getSelectedModuleInTree], cell => {
  if (cell && cell.getLayoutStyleData()) {
    return Object.keys(cell.getLayoutStyleData()).length > 0;
  }
  return false;
});
export const getLayoutStylesBackgroundLayersForNode = createSelector([getLayoutStylesForNode, getActiveBreakpointName], (nodeStyles, activeBreakpoint) => {
  return getBackgroundLayersFromLayoutStyles(nodeStyles, activeBreakpoint);
});
export const getLayoutStylesBackgroundColorForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getBackgroundColorFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesBackgroundImageForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getBackgroundImageFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesBackgroundGradientForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getBackgroundGradientFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesMarginForNode = createSelector([getLayoutStylesForNode, getActiveBreakpointName], (styleData, activeBreakpoint) => {
  return getMarginFromLayoutStyles(styleData, activeBreakpoint);
});
export const getLayoutStylesPaddingForNode = createSelector([getLayoutStylesForNode, getActiveBreakpointName], (styleData, activeBreakpoint) => {
  return getPaddingFromLayoutStyles(styleData, activeBreakpoint);
});
export const getLayoutStylesVerticalAlignmentForNode = createSelector([nodeExtractor], node => {
  return calculateExistingVerticalAlignmentForRow(node);
});
export const getLayoutStylesMaxWidthForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getSectionMaxWidthFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesForceFullWidthForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getSectionForceFullWidthFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesFlexboxPositioningForModule = createSelector([getSelectedModuleInTree], cell => cell ? getFlexboxPositioningFromLayoutStyles(cell.getLayoutStyleData()) : undefined);
const getIsStylingModulesSupported = createSelector([getIsBlogPost], isBlogPost => !isBlogPost);
export const getIsModuleStyleable = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  const isModuleEditable = getIsModuleEditable(state, id);
  const isNotFakeBodyOrTitleModule = !getIsFakeBodyOrTitleModule(state, id);
  const isDefaultModule = getIsDefaultModule(state, id);
  const isCustomCmv2ModuleInLayoutSection = getIsCustomCmv2Module(state, id) && getIsModuleInLayoutSection(state, id);
  const isCmv2 = getIsCMv2Module(state, id);
  const isCmv2BuiltinModule = isCmv2 && isDefaultModule;
  const isCustomOrBuiltinV1Module = !isCmv2;
  const isFakeBodyModule = isFakeBodyModuleName(id);
  const allV2ModuleDefinitionIdsWithStyles = getAllV2ModuleDefinitionIdsWithStylesOnPage(state);
  const anyOtherV2ModuleOfSameTypeWithStyles = !!module && allV2ModuleDefinitionIdsWithStyles.has(getImmutableOrPlain(module, 'module_id'));
  const isGlobal = getIsGlobalModuleGroupOrPartial(state, id);
  const isStylingModulesSupported = getIsStylingModulesSupported(state);
  const modulesFromPostBody = getModulesByNameFromPostBody(state);
  const isBlogPostBodyModule = isImmutable(modulesFromPostBody) ? modulesFromPostBody.has(id) : hasOwn(modulesFromPostBody, id);
  const shouldUseResponsiveBreakpoints = getShouldUseResponsiveBreakpoints(state);
  return (isStylingModulesSupported || isBlogPostBodyModule) && isModuleEditable && isNotFakeBodyOrTitleModule && !isGlobal && (isCmv2BuiltinModule || isCustomCmv2ModuleInLayoutSection && shouldUseResponsiveBreakpoints || isCustomOrBuiltinV1Module || isFakeBodyModule || anyOtherV2ModuleOfSameTypeWithStyles);
});
export const getModuleBreakpointStyles = createSelector([getSelectedModuleInTree, getLayoutStylesForNode, getSelectedModule], (selectedModuleInTree, layoutStylesForNode, selectedModule) => {
  if (selectedModuleInTree) {
    return layoutStylesForNode === null || layoutStylesForNode === void 0 ? void 0 : layoutStylesForNode.breakpointStyles;
  } else if (selectedModule && getImmutableOrPlain(selectedModule, 'styles')) {
    const selectedModuleStyles = isImmutable(selectedModule) ? selectedModule.get('styles').toJS() : selectedModule.styles;
    return selectedModuleStyles === null || selectedModuleStyles === void 0 ? void 0 : selectedModuleStyles.breakpointStyles;
  }
  return undefined;
});