export const hasDataTokensImmutable = module => {
  return module.hasIn(['body', 'data_tokens']);
};
export const hasDataTokensImmer = module => {
  var _module$body;
  return Boolean((_module$body = module.body) === null || _module$body === void 0 ? void 0 : _module$body.data_tokens);
};
export const extractScpPropertiesFromModuleImmutable = module => {
  if (!hasDataTokensImmutable(module)) {
    return [];
  }
  const dataTokenMap = module.getIn(['body', 'data_tokens']);
  return dataTokenMap.valueSeq().map(dataToken => dataToken.split('dynamic_page_hubdb_row.')[1]).filter(Boolean).toJS();
};
export const extractScpPropertiesFromModuleImmer = module => {
  var _module$body2;
  if (!hasDataTokensImmer(module)) {
    return [];
  }
  const dataTokenMap = (_module$body2 = module.body) === null || _module$body2 === void 0 ? void 0 : _module$body2.data_tokens;
  return Object.values(dataTokenMap).map(dataToken => dataToken.split('dynamic_page_hubdb_row.')[1]).filter(Boolean);
};