import { fetchHubDbTable } from 'ContentData/api/HubDb';
// @ts-expect-error upstream dep
import { wrapRequest } from 'ContentUtils/helpers/sentry';
import * as HubApi from '../../api/HubDB';
import * as InstanceApi from '../../api/StructuredContent';
import { RequestStatus } from '../../constants/types';
import { selectActiveHubDbRowId, selectContentTypeName, selectTableName } from '../selectors/instanceEditSelectors';
import { CLEAR_ACTIVE_HUBDB_ROW, SET_ACTIVE_HUBDB_ROW, SET_HUBDB_ROWS_AND_SCHEMA, FETCH_ALL_TABLES, UPDATE_CELL_SUCCEEDED, UPDATE_CELL_ATTEMPTED, UPDATE_ROW_ATTEMPTED, UPDATE_ROW_SUCCEEDED, FETCH_HUBDB_TABLE, FETCH_ALL_ROWS, FETCH_ROW, FETCH_MODULE_SCHEMA, UPDATE_INSTANCE_VALUES, UPDATE_CURRENT_INSTANCE_VALUE, UPDATE_INSTANCE_PROPERTY_META_DATA, SET_CONTENT_TYPE_SCHEMA_ATTEMPTED, SET_CONTENT_TYPE_SCHEMA_SUCCEEDED, SET_INSTANCE_DATA_ATTEMPTED, SET_INSTANCE_DATA_SUCCEEDED, SET_TABLE_AS_MODULE_SCHEMA_SUCCEEDED, SET_TABLE_AS_MODULE_SCHEMA_ATTEMPTED, SET_CONTENT_TYPE_META, FETCH_GROUP_DATA_ATTEMPTED, FETCH_GROUP_DATA_SUCCEEDED, ADD_MODULES_TO_RICH_TEXT_PROPERTY, DELETE_MODULES_IN_RICH_TEXT_PROPERTY } from './ActionTypes';
const makeCatchFunction = (dispatch, actionType) => () => {
  dispatch({
    type: actionType,
    meta: {
      status: RequestStatus.FAILED
    }
  });
};
export const setInstanceEditingHubDBRow = (rowId, instance) => {
  return {
    type: SET_ACTIVE_HUBDB_ROW,
    payload: {
      rowId,
      instance
    }
  };
};
export const updateInstanceValues = rowData => ({
  type: UPDATE_INSTANCE_VALUES,
  payload: {
    row: rowData
  }
});
export const clearInstanceEditingHubDBRow = () => ({
  type: CLEAR_ACTIVE_HUBDB_ROW
});
export const fetchAllTables = () => dispatch => {
  HubApi.fetchAllTables().then(tablesResponse => {
    dispatch({
      type: FETCH_ALL_TABLES,
      payload: {
        availableTables: tablesResponse.objects
      },
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  }).catch(makeCatchFunction(dispatch, FETCH_ALL_TABLES));
};
export const fetchTable = (tableId, contentAccessor) => dispatch => {
  const defaultResolver = fetchHubDbTable.bind({}, tableId);
  const resolver = contentAccessor || defaultResolver;
  resolver(tableId).then(tablesResponse => {
    dispatch({
      type: FETCH_HUBDB_TABLE,
      payload: {
        hubDbTable: tablesResponse
      },
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  }).catch(makeCatchFunction(dispatch, FETCH_HUBDB_TABLE));
};
export const fetchAllRows = (tableId, contentAccessor) => dispatch => {
  const defaultResolver = HubApi.fetchAllRows.bind({}, tableId);
  const resolver = contentAccessor || defaultResolver;
  dispatch({
    type: FETCH_ALL_ROWS,
    meta: {
      status: RequestStatus.PENDING
    }
  });
  resolver().then(rowResponse => {
    dispatch({
      type: FETCH_ALL_ROWS,
      payload: {
        rows: rowResponse.objects
      },
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  }).catch(makeCatchFunction(dispatch, FETCH_ALL_ROWS));
};
export const fetchRowSingleton = (tableId, rowId, contentAccessor) => dispatch => {
  const defaultResolver = HubApi.fetchRow.bind({}, tableId, rowId);
  const resolver = contentAccessor || defaultResolver;
  dispatch({
    type: FETCH_ROW,
    meta: {
      status: RequestStatus.PENDING
    }
  });
  resolver().then(rowResponse => {
    dispatch({
      type: FETCH_ROW,
      payload: {
        row: rowResponse
      },
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  }).catch(makeCatchFunction(dispatch, FETCH_ROW));
};
export const fetchInstanceModuleSchema = (tableName, contentAccessor) => dispatch => {
  const defaultResolver = HubApi.fetchRowModuleSchema.bind({}, tableName);
  const resolver = contentAccessor || defaultResolver;
  dispatch({
    type: FETCH_MODULE_SCHEMA,
    meta: {
      status: RequestStatus.PENDING
    }
  });
  resolver(tableName).then(schemaResponse => {
    dispatch({
      type: FETCH_MODULE_SCHEMA,
      payload: {
        tableSchema: schemaResponse.tableAsModule
      },
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  }).catch(makeCatchFunction(dispatch, FETCH_MODULE_SCHEMA));
};
export const fetchRowAndSchemas = (tableName, dataCallback) => (dispatch, getState) => {
  const currentInstanceId = selectActiveHubDbRowId(getState());
  if (!currentInstanceId) {
    throw new Error('currentInstanceId is missing');
  }
  const fetchDefaultData = () => HubApi.fetchRow(tableName, currentInstanceId);
  const fetchData = dataCallback || fetchDefaultData;
  dispatch({
    type: SET_HUBDB_ROWS_AND_SCHEMA,
    payload: {},
    meta: {
      status: RequestStatus.PENDING
    }
  });
  Promise.all([fetchData(), HubApi.fetchRowModuleSchema(tableName)]).then(([data, schema]) => {
    dispatch({
      type: SET_HUBDB_ROWS_AND_SCHEMA,
      payload: {
        row: data,
        tableSchema: schema.tableAsModule
      },
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  }).catch(() => {
    dispatch({
      type: SET_HUBDB_ROWS_AND_SCHEMA,
      payload: {},
      meta: {
        status: RequestStatus.FAILED
      }
    });
  });
};
export const persistUpdateCell = options => dispatch => {
  const {
    tableId,
    rowId,
    columnId,
    value,
    updatedRow,
    undoable,
    originalRow
  } = options;
  const redo = undoable ? persistUpdateCell(Object.assign({}, options, {
    undoable: true,
    updatedRow
  })) : null;
  const undo = undoable ? persistUpdateCell(Object.assign({}, options, {
    undoable: false,
    originalRow,
    updatedRow: originalRow
  })) : null;
  dispatch({
    type: UPDATE_CELL_ATTEMPTED,
    data: value,
    tableId,
    rowId,
    columnId,
    redo,
    undo,
    present: redo
  });
  dispatch(updateInstanceValues(updatedRow));
  wrapRequest(InstanceApi.updateCell(tableId, rowId, columnId, {
    value
  }), 'UPDATE_CELL').then(data => {
    dispatch({
      type: UPDATE_CELL_SUCCEEDED,
      data,
      tableId,
      rowId,
      columnId
    });
  }).catch(() => {
    // TODO: Determine error handling
  });
};
export function persistUpdateRow(dispatch, options) {
  const {
    tableId,
    rowId,
    updatedRow,
    present
  } = options;
  dispatch({
    type: UPDATE_ROW_ATTEMPTED,
    present,
    tableId,
    updatedRow,
    rowId
  });
  wrapRequest(InstanceApi.updateRow(tableId, rowId, updatedRow), 'UPDATE_ROW').then(data => {
    dispatch({
      type: UPDATE_ROW_SUCCEEDED,
      present,
      tableId,
      row: data,
      rowId
    });
  }).catch(() => {
    // TODO: Determine error handling
  });
}
export const sendUpdateRow = options => dispatch => {
  const {
    originalRow,
    tableId,
    rowId,
    undoable,
    updatedRow
  } = options;
  const redo = undoable ? sendUpdateRow(Object.assign({}, options, {
    undoable: false,
    updatedRow
  })) : null;
  const undo = undoable ? sendUpdateRow(Object.assign({}, options, {
    undoable: false,
    updatedRow: originalRow
  })) : null;
  dispatch({
    type: UPDATE_ROW_ATTEMPTED,
    originalRow,
    updatedRow
  });
  dispatch(updateInstanceValues(updatedRow));
  wrapRequest(InstanceApi.updateRow(tableId, rowId, updatedRow), 'UPDATE_ROW').then(data => {
    dispatch({
      type: UPDATE_ROW_SUCCEEDED,
      tableId,
      row: data,
      rowId,
      present: sendUpdateRow({
        undoable: false,
        updatedRow
      }),
      redo,
      undo
    });
  }).catch(() => {
    // TODO: Determine error handling
  });
};
export const updateInstanceValue = (fieldKey, newVal, {
  metaData,
  undoData = {}
} = {}) => dispatch => {
  if (!undoData.groupKey) {
    undoData.groupKey = fieldKey;
  }
  return dispatch({
    type: UPDATE_CURRENT_INSTANCE_VALUE,
    payload: {
      fieldKey,
      newVal,
      metaData
    },
    undoData
  });
};
export const addModulesToRichTextProperty = (fieldKey, newModuleInstances, html, undoData = {}, metaData = {}) => {
  return {
    type: ADD_MODULES_TO_RICH_TEXT_PROPERTY,
    payload: {
      newModuleInstances,
      html,
      fieldKey
    },
    needsResponse: false,
    metaData,
    undoData
  };
};
export const deleteModulesInRichTextProperty = (fieldKey, removedModuleInstances, html, undoData = {}, metaData = {}) => {
  return {
    type: DELETE_MODULES_IN_RICH_TEXT_PROPERTY,
    payload: {
      removedModuleInstances,
      html,
      fieldKey
    },
    metaData,
    undoData
  };
};
export const updateInstanceMetaData = (fieldKey, metaData) => ({
  type: UPDATE_INSTANCE_PROPERTY_META_DATA,
  payload: {
    fieldKey,
    metaData
  }
});

/**
 * Fetches your content type's table schema.
 * @param contentTypeName The name of your content type.
 * @param tableName The name of the content type table
 */
export const fetchContentTypeSchema = (contentTypeName, tableName) => async dispatch => {
  dispatch({
    type: SET_CONTENT_TYPE_SCHEMA_ATTEMPTED,
    meta: {
      status: RequestStatus.PENDING
    }
  });
  try {
    const response = await InstanceApi.fetchContentTypeTableSchema(contentTypeName, tableName);
    dispatch({
      type: SET_CONTENT_TYPE_SCHEMA_SUCCEEDED,
      payload: response,
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  } catch (error) {
    dispatch({
      type: SET_CONTENT_TYPE_SCHEMA_ATTEMPTED,
      payload: {},
      meta: {
        status: RequestStatus.FAILED
      }
    });

    // Without this we get a floating promise error the breaks consuming apps'
    // builds. This is consider a "Catch and rethrow" pattern that is the
    // recommended way to handle try/catch blocks to overcome this eslint error.
    // More information can be found here: https://product.hubteam.com/docs/frontend/kb/promises.html#async-functions
    throw error;
  }
};

/**
 * Fetches instance data
 * @param tableName The name of the content type table associated with the current instance
 * @param dataCallback An optional callback function when not using HubDB API's directly.
 */
export const fetchInstanceData = (tableName, dataCallback) => async (dispatch, getState) => {
  const currentInstanceId = selectActiveHubDbRowId(getState());
  if (!currentInstanceId) {
    throw new Error('currentInstanceId is missing');
  }
  const fetchDefaultData = () => HubApi.fetchRow(tableName, currentInstanceId);
  const fetchData = dataCallback || fetchDefaultData;
  dispatch({
    type: SET_INSTANCE_DATA_ATTEMPTED,
    payload: {},
    meta: {
      status: RequestStatus.PENDING
    }
  });
  try {
    const instanceData = await fetchData();
    dispatch({
      type: SET_INSTANCE_DATA_SUCCEEDED,
      payload: instanceData,
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  } catch (error) {
    dispatch({
      type: SET_INSTANCE_DATA_ATTEMPTED,
      payload: {},
      meta: {
        status: RequestStatus.FAILED
      }
    });

    // Without this we get a floating promise error the breaks consuming apps'
    // builds. This is consider a "Catch and rethrow" pattern that is the
    // recommended way to handle try/catch blocks to overcome this eslint error.
    // More information can be found here: https://product.hubteam.com/docs/frontend/kb/promises.html#async-functions
    throw error;
  }
};

/**
 * Fetches instance group data
 * @param groupId The ID of your content type's instance Group.
 */
export const fetchInstanceGroupData = fetchGroupCallback => async dispatch => {
  dispatch({
    type: FETCH_GROUP_DATA_ATTEMPTED,
    payload: {},
    meta: {
      status: RequestStatus.PENDING
    }
  });
  try {
    const groupData = await fetchGroupCallback();
    if (!groupData) {
      throw new Error(`Instance's group data is missing`);
    }
    dispatch({
      type: FETCH_GROUP_DATA_SUCCEEDED,
      payload: groupData,
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  } catch (error) {
    dispatch({
      type: FETCH_GROUP_DATA_ATTEMPTED,
      payload: {},
      meta: {
        status: RequestStatus.FAILED
      }
    });

    // Without this we get a floating promise error the breaks consuming apps'
    // builds. This is consider a "Catch and rethrow" pattern that is the
    // recommended way to handle try/catch blocks to overcome this eslint error.
    // More information can be found here: https://product.hubteam.com/docs/frontend/kb/promises.html#async-functions
    throw error;
  }
};

/**
 * Fetches a module representation of the content type table schema. This is used
 * within Scalable Editor to render and edit fields hydrated by your instance data
 * @param tableName The name of the content type table
 */
export const fetchTableAsModuleSchema = tableName => async (dispatch, getState) => {
  const currentInstanceId = selectActiveHubDbRowId(getState());
  if (!currentInstanceId) {
    throw new Error('currentInstanceId is missing');
  }
  dispatch({
    type: SET_TABLE_AS_MODULE_SCHEMA_ATTEMPTED,
    meta: {
      status: RequestStatus.PENDING
    }
  });
  try {
    const moduleSchema = await HubApi.fetchRowModuleSchema(tableName);
    const tableAsModuleSchema = moduleSchema && moduleSchema.tableAsModule;
    dispatch({
      type: SET_TABLE_AS_MODULE_SCHEMA_SUCCEEDED,
      payload: tableAsModuleSchema,
      meta: {
        status: RequestStatus.SUCCEEDED
      }
    });
  } catch (error) {
    dispatch({
      type: SET_TABLE_AS_MODULE_SCHEMA_ATTEMPTED,
      payload: {},
      meta: {
        status: RequestStatus.FAILED
      }
    });

    // Without this we get a floating promise error the breaks consuming apps'
    // builds. This is consider a "Catch and rethrow" pattern that is the
    // recommended way to handle try/catch blocks to overcome this eslint error.
    // More information can be found here: https://product.hubteam.com/docs/frontend/kb/promises.html#async-functions
    throw error;
  }
};

/**
 * Sets the content type name and table name in the store
 * @param contentTypeName The name of the content type
 * @param tableName The name of the content type table
 * @returns
 */
export const setContentTypeMeta = (contentTypeName, tableName) => dispatch => {
  dispatch({
    type: SET_CONTENT_TYPE_META,
    payload: {
      contentTypeName,
      tableName
    }
  });
};

/**
 * Creates an SCP selection option then refetches the content type table schema
 * @param propertyName The name of the property to create the option for
 * @param option The option to create
 */
export const createSelectOption = (propertyName, option) => (dispatch, getState) => {
  const contentTypeName = selectContentTypeName(getState());
  const tableName = selectTableName(getState());
  return InstanceApi.createContentTypeSelectOption(contentTypeName, tableName, propertyName, option).then(async newOption => {
    await dispatch(fetchContentTypeSchema(contentTypeName, tableName));
    return newOption;
  });
};

/**
 * Updates an SCP selection option then refetches the content type table schema
 * @param propertyName The name of the property to update the option for
 * @param option The option with values to update
 * @returns
 */
export const updateSelectOption = (propertyName, option) => (dispatch, getState) => {
  const contentTypeName = selectContentTypeName(getState());
  const tableName = selectTableName(getState());
  return InstanceApi.updateContentTypeSelectOption(contentTypeName, tableName, propertyName, option).then(async updatedOption => {
    await dispatch(fetchContentTypeSchema(contentTypeName, tableName));
    return updatedOption;
  });
};

/**
 * Deletes an SCP selection option then refetches the content type table schema
 * @param propertyName The name of the property to delete the option for
 * @param option The option to delete
 * @returns
 */
export const deleteSelectOption = (propertyName, option) => (dispatch, getState) => {
  const contentTypeName = selectContentTypeName(getState());
  const tableName = selectTableName(getState());
  return InstanceApi.deleteContentTypeSelectOption(contentTypeName, tableName, propertyName, option).then(async () => {
    await dispatch(fetchContentTypeSchema(contentTypeName, tableName));
  });
};