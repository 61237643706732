'use es6';

import { cloneTreeFromLayoutDataApi, exportCellAsModule, exportCell, importTreeFromLayoutDataApi } from 'layout-data-lib/LayoutDataTree/serialize';
import { batchTreeMutations } from 'layout-data-lib/CellAndRowsTree/insertHelpers';
import { prepareLeafNodeStyles } from 'layout-data-lib/alignment/helpers';
import { getSchemaForModuleId, getSchemaFromSidebarAddListForModuleId } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { createNewModuleFromSchema, createNewModuleSchemaJson } from 'ContentUtils/helpers/ModuleCreationHelpers';
import { isModuleTypeWithFlexboxHorizontalStylesEnabled } from 'ContentEditorUI/data/moduleUtils';
import { getIsUngatedForMergeInDefaultValues, getHasSmartContentScope } from 'ContentEditorUI/redux/selectors/authSelectors';
import { cloneSmartContentRulesForModule } from 'ContentEditorUI/api/SmartContentApi';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import I18n from 'I18n';
import { getAllFieldNamesOnModuleSpec } from 'ContentEditorUI/data/moduleUtils';
export const NODE_TYPES = {
  SECTION: 'section',
  ROW: 'row',
  COLUMN: 'column',
  MODULE: 'module'
};
const prepareNewModuleData = (newModuleSchemaJson, options) => {
  if (newModuleSchemaJson) {
    const newModuleData = Object.assign({}, newModuleSchemaJson);
    newModuleData.params = Object.assign({}, newModuleData.body);
    delete newModuleData.body;
    newModuleData.styles = prepareLeafNodeStyles(newModuleData.styles, options);
    return newModuleData;
  }
  return null;
};
export const prepareNewModuleDataHelper = (newModuleSchemaJson, newModuleType) => {
  const supportsFlexboxHorizontalCentering = isModuleTypeWithFlexboxHorizontalStylesEnabled(newModuleType);
  return prepareNewModuleData(newModuleSchemaJson, {
    supportsFlexboxHorizontalCentering
  });
};
export const modifyColumnWidths = (tree, leftColumnInfo, rightColumnInfo) => {
  const leftTargetCell = tree.findCell(leftColumnInfo.id);
  const rightTargetCell = tree.findCell(rightColumnInfo.id);
  let firstData;
  let secondData;
  // Row units cannot exceed 12, we must take before we give
  if (leftTargetCell.getWidth() > leftColumnInfo.newWidth) {
    firstData = {
      id: leftTargetCell.getName(),
      width: leftColumnInfo.newWidth
    };
    secondData = {
      id: rightTargetCell.getName(),
      width: rightColumnInfo.newWidth
    };
  } else {
    firstData = {
      id: rightTargetCell.getName(),
      width: rightColumnInfo.newWidth
    };
    secondData = {
      id: leftTargetCell.getName(),
      width: leftColumnInfo.newWidth
    };
  }
  const {
    tree: tempNewTree
  } = tree.modifyCellWidth(firstData.id, firstData.width);
  const {
    tree: newTree
  } = tempNewTree.modifyCellWidth(secondData.id, secondData.width);
  return newTree;
};
const generateLayoutColumns = columns => {
  const rowsObject = {};
  let widthCount = 0;
  columns.forEach(column => {
    const name = `cell_${Date.now() + widthCount}`;
    rowsObject[`${widthCount}`] = {
      name,
      type: 'cell',
      widths: [],
      x: widthCount,
      rows: [],
      w: column
    };
    widthCount += column;
  });
  return rowsObject;
};
export const generateLayoutDataForDefaultSection = columns => {
  const name = 'temp-layout-section-0';
  const rows = generateLayoutColumns(columns);
  return {
    name,
    type: 'cell',
    w: 12,
    widgets: [],
    x: 0,
    rows: [Object.assign({}, rows)]
  };
};
const handleSmartRuleAndModuleDataProcessingError = e => {
  if (e && e.cause && e.cause.moduleSchemaErrors && e.cause.previousTree) {
    console.error('Error in hydrating section, missing module schemas for the following module ids');
    e.cause.moduleSchemaErrors.forEach(schemaError => {
      console.log(`id: ${schemaError.moduleId}, path: ${schemaError.path} `);
    });
    return {
      hasError: true,
      moduleSchemaErrors: e.cause.moduleSchemaErrors,
      previousTree: e.cause.previousTree
    };
  } else {
    console.error('Error in hydrating section: ', e);
    FloatingAlertStore.addAlert({
      type: 'danger',
      titleText: I18n.text('addSectionPanel.error.modal.title'),
      message: I18n.text('addSectionPanel.error.alert.message')
    });
  }
  return {
    hasError: true
  };
};
const handleSmartRuleCloneError = e => {
  console.error('Error in cloning smart rules: ', e);
  FloatingAlertStore.addAlert({
    type: 'danger',
    titleText: I18n.text('addSectionPanel.error.alert.smartRuleTitle'),
    message: I18n.text('addSectionPanel.error.alert.message')
  });
  return {
    hasError: true
  };
};
const processHydratedModuleDataInTreeHelper = (allModules, state, layoutSectionIdToDropInto) => {
  const customSectionModuleSchemas = [];
  const modulesWithoutSchemas = [];
  const isUngatedForMergeInDefaultValues = getIsUngatedForMergeInDefaultValues(state);
  allModules.forEach(moduleTreeNode => {
    const exportedCell = exportCellAsModule(moduleTreeNode.getName(), moduleTreeNode.getValue());
    const moduleSchema = getSchemaFromSidebarAddListForModuleId(state, exportedCell.params.module_id);
    if (!moduleSchema) {
      modulesWithoutSchemas.push({
        moduleId: exportedCell.params.module_id,
        path: exportedCell.params.path,
        cellId: exportedCell.id
      });
    } else {
      const module = createNewModuleFromSchema(moduleSchema, {
        setMergeInDefaultFieldValuesToFalse: isUngatedForMergeInDefaultValues
      });
      module.layout_section_id = layoutSectionIdToDropInto;
      // The name generated by createNewModuleFromSchema isn't needed, instead, use the name that was generated via the
      // cloneTreeFromLayoutDataApi call above
      module.name = moduleTreeNode.getName();
      const newModuleSchemaJson = createNewModuleSchemaJson(moduleSchema, module);
      customSectionModuleSchemas.push(newModuleSchemaJson);
    }
  });
  return {
    customSectionModuleSchemas,
    modulesWithoutSchemas
  };
};
function getDefaultBodyFromTreeCell(state, cell) {
  const treeModule = exportCellAsModule(cell.getName(), cell.getValue());
  const moduleSchema = getSchemaForModuleId(state, cell.getParams().module_id);
  const fieldTypeNames = getAllFieldNamesOnModuleSpec(moduleSchema);
  const filteredParams = Object.keys(treeModule.params).reduce((params, paramKey) => {
    if (fieldTypeNames.includes(paramKey)) {
      params[paramKey] = treeModule.params[paramKey];
    }
    return params;
  }, {});
  return filteredParams;
}
export const hydrateCustomSectionIntoLayoutTree = (layoutData, layoutSectionIdToDropInto, state) => {
  const hasSmartContentAccess = getHasSmartContentScope(state);

  // Generate a unique set of names for the layout data and hydrate it into a tree.
  // Keep the smart content ids, we'll handle cloning those next
  const hydratedTree = cloneTreeFromLayoutDataApi(layoutData, {
    stripSmartContentData: false
  });
  const cloneSmartRulePromises = [];

  // TODO Investigate if this is still true:
  // Modules coming from the sections end point have overrideable set to false, make
  // sure we update this temp tree to force all modules to be overridable
  const {
    tree: overridableTree
  } = batchTreeMutations(hydratedTree, mutableTree => {
    mutableTree.allModules().forEach(m => {
      const moduleName = m.getName();
      const definitionId = m.getParams().definition_id;
      const hasSmartContent = !!definitionId && definitionId !== 'null';
      if (hasSmartContent) {
        if (hasSmartContentAccess) {
          // Make a request to targeted content api to clone these smart rules, push
          // request promise into array because there may be many modules with smart rules
          cloneSmartRulePromises.push(cloneSmartContentRulesForModule({
            moduleName,
            definitionId
          }));
        } else {
          mutableTree.mergeIntoCellParams(moduleName, {
            smart_objects: [],
            definition_id: null,
            smart_type: null
          });
        }
      }
      mutableTree.mergeIntoCellParams(moduleName, {
        overrideable: true
      });
    });
    if (window.SELENIUM_IS_RUNNING) {
      const sectionRowName = mutableTree.getRootCell().getRows()[0].getName();
      mutableTree.mergeIntoRowValue(sectionRowName, {
        cssClass: 'SELENIUM-INSERTED-SECTION'
      });
    }
  });

  // Wait until all (if any) requests to endpoint for cloning smart rules finises
  return Promise.all(cloneSmartRulePromises).then(newSmartRuleData => {
    // Find all the modules in the tree that had smart content and update their definition / criterion ids
    const {
      tree: treeWithClonedSmartRules
    } = batchTreeMutations(overridableTree, mutableTree => {
      newSmartRuleData.forEach(clonedRule => {
        const module = mutableTree.findCell(clonedRule.moduleName);
        const existingParams = module.getParams();
        let smartObjects = existingParams.smart_objects;
        const originalSmartObjectsLength = smartObjects.length;
        let hasFoundCriteriaIdNotInExistingSmartObjects = false;
        const clonedCriteria = clonedRule.clonedSmartContentData.criteria;
        clonedCriteria.forEach(newCriteria => {
          const matchingExistingSmartObjectIndex = smartObjects.findIndex(obj => obj.criterion_id === newCriteria.clonedFrom);
          if (matchingExistingSmartObjectIndex >= 0) {
            smartObjects[matchingExistingSmartObjectIndex].criterion_id = newCriteria.id;
          } else {
            // The original smart definition changed and has added a new rule. Manually add that rule with the
            // "default" value
            hasFoundCriteriaIdNotInExistingSmartObjects = true;
            const defaultBody = getDefaultBodyFromTreeCell(state, module);
            smartObjects.push({
              body: defaultBody,
              criterion_id: newCriteria.id,
              order: smartObjects.length,
              target_type: newCriteria.targetType
            });
          }
        });

        // Remove any extra smart rules that were present on the smart definition when the section was created
        // but no longer exist on the smart definition. Also, make sure that the criteria are in the correct order
        // if any were added or any needed to be removed
        if (originalSmartObjectsLength !== clonedCriteria.length || hasFoundCriteriaIdNotInExistingSmartObjects) {
          // Ensure that the smart object order is correct as well
          clonedCriteria.sort((criteriaA, criteriaB) => criteriaA.precedence - criteriaB.precedence);
          smartObjects = clonedCriteria.reduce((sortedFilteredSmartObjects, criteria) => {
            const matchedSmartObject = smartObjects.find(smartObj => smartObj.criterion_id === criteria.id);
            // This shouldn't happen, but trying to be super safe so we don't have an undefined smart object
            if (matchedSmartObject) {
              sortedFilteredSmartObjects.push(matchedSmartObject);
            }
            return sortedFilteredSmartObjects;
          }, []);
        }
        // Merge new ids back into module params, overriding the old ones
        mutableTree.mergeIntoCellParams(clonedRule.moduleName, {
          smart_objects: smartObjects,
          definition_id: clonedRule.clonedSmartContentData.id
        });
      });
    });

    // Generate the module data from the params in the layout data and
    // the module schema. Call the same helper methods WidgetAddController would call
    // for adding modules to flex columns or layout sections, but make sure we keep this
    // "instance" data.
    const {
      customSectionModuleSchemas,
      modulesWithoutSchemas
    } = processHydratedModuleDataInTreeHelper(treeWithClonedSmartRules.allModules(), state, layoutSectionIdToDropInto);
    if (modulesWithoutSchemas.length > 0) {
      throw new Error('Missing module schemas', {
        cause: {
          moduleSchemaErrors: modulesWithoutSchemas,
          previousTree: treeWithClonedSmartRules
        }
      });
    }
    const rowToMove = treeWithClonedSmartRules.getRootCell().getRows()[0];
    return {
      tempCustomSectionTree: treeWithClonedSmartRules,
      rowIdToMove: rowToMove.getName(),
      customSectionModuleSchemas
    };
  }, handleSmartRuleCloneError).catch(handleSmartRuleAndModuleDataProcessingError);
};
export const hydratePreviousSectionTreeIntoLayoutTreeWithoutMissingModuleSchemas = (previousTree, layoutSectionIdToDropInto, state) => {
  const {
    customSectionModuleSchemas,
    modulesWithoutSchemas
  } = processHydratedModuleDataInTreeHelper(previousTree.allModules(), state, layoutSectionIdToDropInto);
  let treeWithoutMissingModuleSchemas = previousTree;
  modulesWithoutSchemas.forEach(module => {
    ({
      tree: treeWithoutMissingModuleSchemas
    } = previousTree.removeCell(module.cellId));
  });
  const rowIdToMove = treeWithoutMissingModuleSchemas.getRootCell().getRows()[0].getName();
  return {
    tempCustomSectionTree: treeWithoutMissingModuleSchemas,
    rowIdToMove,
    customSectionModuleSchemas
  };
};
export const getNodeType = node => {
  let treeNodeType = null;
  if (node.isRow() && node.getParent().isRoot()) {
    treeNodeType = NODE_TYPES.SECTION;
  } else if (node.isRow()) {
    treeNodeType = NODE_TYPES.ROW;
  } else if (node.isCell() && node.isModule()) {
    treeNodeType = NODE_TYPES.MODULE;
  } else {
    treeNodeType = NODE_TYPES.COLUMN;
  }
  return treeNodeType;
};

// Rows will be copied into the root cell of a new tree, then root cell will be exported
export const exportTreeFragmentAsLayoutDataJson = node => {
  if (node.isCell()) {
    return exportCell(node);
  } else {
    // Else is row or section
    const rootCellName = 'row-wrapper-cell';
    const rowFragmentWrapperCell = {
      name: rootCellName,
      type: 'cell',
      w: 12,
      widgets: [],
      x: 0,
      rows: []
    };
    const tempTree = importTreeFromLayoutDataApi(rowFragmentWrapperCell);
    const {
      tree
    } = tempTree.moveRowTo(node.getName(), {
      originTree: node._treeRef,
      insideCellName: rootCellName
    });
    const rootCell = tree.getRootCell();
    return exportCell(rootCell);
  }
};
export const addStaticSectionLayoutDataToMockTree = layoutData => {
  // When inserting into a dnd area, `hydrateCustomSectionIntoLayoutTree`
  // Needs the info inside a tree - copies the static section row from that tree
  // to the existing tree
  // and then deletes the tree.

  // Basing props on whats used in `importTreeFromLayoutDataApi`
  // where its used in `hydrateCustomSectionIntoLayoutTree`
  return {
    x: 0,
    w: 12,
    type: 'cell',
    name: 'fake-tree-container',
    id: 'fake-tree-container',
    label: 'fake-tree-container-label',
    params: {},
    styles: null,
    order: 0,
    rows: [{
      0: layoutData
    }]
    // potentially will need rowMetaData longer term, but leaving out as
    // importStaticSectionHelper is not using it at the moment
  };
};
export const createTreeFragmentForEmbed = embedId => {
  return {
    x: 0,
    w: 12,
    type: 'cell',
    name: 'fake-tree-container',
    id: 'fake-tree-container',
    label: 'fake-tree-container-label',
    params: {},
    styles: null,
    order: 0,
    rows: [{
      0: {
        name: `cell_${Date.now()}`,
        type: 'embed',
        widths: [],
        x: 0,
        rows: [],
        w: 12,
        params: {
          embed_id: embedId
        }
      }
    }]
  };
};