'use es6';

import { getImmutableOrPlain } from 'ContentEditorUI/utils/dataHelpers';
export const findModuleById = (modules, id) => {
  // Prevent matching any module that has id === undefined
  if (id == null) {
    return undefined;
  }
  return modules[id] || modules[`widget_${id}`] || modules[`module_${id}`] || Object.values(modules).find(m => m.get('id') === id);
};
export const findModuleByIdImmer = (modules, id) => {
  // Prevent matching any module that has id === undefined
  if (id == null) {
    return undefined;
  }
  return modules[id] || modules[`widget_${id}`] || modules[`module_${id}`] || Object.values(modules).find(m => m.id === id);
};
export const isCmv2Module = (module, schema) => {
  const type = getImmutableOrPlain(module, 'type');
  if (type === 'module') {
    return true;
  }
  if (type === 'custom_widget') {
    const schemaVersion = schema.schemaVersion;
    return schemaVersion && schemaVersion === 2;
  }
  return false;
};
export const isCustomCmv2Module = schema => {
  return schema && (schema.content_widget_type === 'custom_widget' || schema.type === 'custom_widget') && schema.schemaVersion === 2 && !schema.default;
};
export const getIconForModule = (module, schema, schemaFromBuiltInMapping) => {
  if (getImmutableOrPlain(module, 'type') === 'module' || getImmutableOrPlain(module, 'type') === 'custom_widget') {
    return schema.icon;
  } else if (schemaFromBuiltInMapping) {
    return schemaFromBuiltInMapping.icon;
  }
  return null;
};
export const moduleHasAnyJs = schema => {
  return !!schema.js;
};
export const moduleHasAnyJsOrCss = schema => {
  return moduleHasAnyJs(schema) || schema.css;
};
const LINKED_IMAGE_MODULE_ID_QA = 802481;
const LINKED_IMAGE_MODULE_ID_PROD = 1155231;
export const isLinkedImageModuleId = moduleId => moduleId === LINKED_IMAGE_MODULE_ID_QA || moduleId === LINKED_IMAGE_MODULE_ID_PROD;
export const isEmailBodyName = name => {
  return name === 'hs_email_body' || name === 'email_body';
};
export const isV1GlobalModule = module => {
  return module && getImmutableOrPlain(module, 'type') === 'global_widget';
};
export const isCmv2GlobalModule = (module, moduleSchema) => {
  return isCmv2Module(module, moduleSchema) && !!(moduleSchema && moduleSchema.global);
};
const MODULES_TYPES_WITH_FLEXBOX_HORIZONTAL_STYLES_ENABLED = ['image', 'linked_image', 'logo', 'cta',
// LP starter only modules
'button'];
export const isModuleTypeWithFlexboxHorizontalStylesEnabled = type => MODULES_TYPES_WITH_FLEXBOX_HORIZONTAL_STYLES_ENABLED.includes(type);
export const builtinFormModuleHasFormIdSet = module => {
  return !!module.getIn(['body', 'form_to_use']) || !!module.getIn(['body', 'form', 'form_id']);
};
export const getAllFieldNamesOnModuleSpec = moduleSpec => (moduleSpec.fields || []).map(field => field.name);