import { BaseMockImmutableObject } from './BaseMockImmutableObject';
import { immerable, produce } from 'immer';
import cloneDeep from './cloneDeep';
// @ts-expect-error missing types
import { freeze } from 'immer';
const MockTypedKeyValueImmutableMap = class MockTypedKeyValueImmutableMap extends BaseMockImmutableObject {
  constructor(data) {
    super();
    this[immerable] = true;
    const jsonValue = Object.assign({}, data);
    Object.assign(this, jsonValue);
    freeze(this, true);
  }
  toJSON() {
    return cloneDeep(Object.assign({}, this)); // only when DeserializedType = JSONType, enforced using the different overrides for ImmutableObject;
  }
  clear() {
    return produce(this, draft => {
      Object.assign(draft, new MockTypedKeyValueImmutableMap({}));
    });
  }
  logUsage(methodName) {
    super.logUsage(methodName);
  }
};
export default MockTypedKeyValueImmutableMap;