'use es6';

import { INPAGE_MODULE_BLOCKLIST } from 'ContentEditorUI/lib/widgetEdit/constants';
import { createSelector } from 'reselect';
import { getAuth } from 'ContentEditorUI/redux/selectors/authSelectors';
import { processModuleForIPEUI } from 'ContentEditorUI/lib/InpageUtils';
import { getModules, getUneditableModules, makeGetAnyModuleById, getModuleLists } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { convertRgbaStringToRgbaObject, convertRgbaToHexAndAlphaInt } from 'ContentUtils/helpers/ColorsHelpers';
import { buildTreeNodeDomSelector } from 'layout-dnd-utils/layoutTreeStyles/helpers';
import { getModuleSchemas, getBuiltInTypesByModuleId, getCustomModuleIds, buildFakeModuleSchemasSlice } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { buildFakeModuleSlice, buildFakeModuleSliceImmer } from './moduleSelectorHelpers';
import { InpageModes } from 'ContentEditorUI/lib/app/constants';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsBlogPost } from './contentReadOnlyDataSelectors';
import { getIsUngatedForImmerModuleReducer } from './authSelectors';
const getInpageData = basicSelector(state => state.inpageData);
const createInpageSelector = field => {
  return createSelector([getInpageData], inpageData => inpageData ? inpageData.get(field) : null);
};
export const getCurrentInpageMode = createInpageSelector('inpageMode');
export const getHighlightedWidgetId = createInpageSelector('highlightedWidgetId');
export const getHighlightedWidgetContainerId = createInpageSelector('highlightedWidgetContainerId');
export const getPreviewHtml = createInpageSelector('html');
export const getPreviewHtmlBody = createInpageSelector('htmlBody');
export const getScrollToModuleId = createInpageSelector('scrollToModuleId');
export const getScrollToNodePositionId = createInpageSelector('scrollToNodePositionInfo');
export const getIsDraggingModule = createInpageSelector('isDraggingModule');
export const getIsInLegacyLayoutMode = createSelector(getCurrentInpageMode, currentInpageMode => currentInpageMode === InpageModes.LAYOUT);
export const getPreviewViewportDimensions = createInpageSelector('previewViewportDimensions');
const getPreviewWrapperScroll = createInpageSelector('previewWrapperScroll');
export const getPreviewScrollPositionImmutable = createInpageSelector('previewScrollPosition');
export const getPreviewScrollPosition = createSelector(getPreviewScrollPositionImmutable, previewScrollPositionImmutable => previewScrollPositionImmutable.toJS());
export const getHasInpagePreviewUnexpectedlyUnloaded = createInpageSelector('inpagePreviewUnexpectedlyUnloaded');
export const getFailedInpageAssets = createInpageSelector('failedInpageAssets');
export const getDomReadyBlockingResource = createInpageSelector('domReadyBlockingResource');
export const getInpageJsLoadedMultipleTimes = createInpageSelector('inpageJsLoadedMultipleTimes');
export const getPreviewViewportWidth = createSelector(getPreviewViewportDimensions, previewViewportDimensions => previewViewportDimensions.get('width', 0));
export const getPreviewViewportHeight = createSelector(getPreviewViewportDimensions, previewViewportDimensions => previewViewportDimensions.get('height', 0));
export const getPreviewWrapperScrollLeft = createSelector(getPreviewWrapperScroll, previewWrapperScroll => {
  return previewWrapperScroll.get('scrollLeft', 0);
});
export const getPreviewIframeNeedsRefresh = createInpageSelector('iframeNeedsRefresh');
export const getComputedStylesForAllSelectors = createInpageSelector('computedStylesBySelector');
const getComputedStylesForSelector = basicSelectorWithStats((state, {
  selector
}) => {
  if (!selector) {
    throw new Error('Must pass in a selector prop to getComputedStylesForSelector');
  }
  const stylesBySelector = getComputedStylesForAllSelectors(state);
  return stylesBySelector.get(selector);
});
const getComputedStylesForNode = basicSelectorWithStats((state, {
  node
}) => {
  if (!node) {
    throw new Error('Must pass in a node prop to getObservedComputedStylesForNode');
  }
  const stylesBySelector = getComputedStylesForAllSelectors(state);
  const selectorForNode = buildTreeNodeDomSelector(node);
  return stylesBySelector.get(selectorForNode);
});
export const makeGetComputedBackgroundColorForNode = () => createSelector(getComputedStylesForNode, computedStyles => {
  if (!computedStyles) {
    return null;
  }
  const backgroundColor = computedStyles.get('background-color');
  if (backgroundColor) {
    const rgbaObject = convertRgbaStringToRgbaObject(backgroundColor);
    if (rgbaObject) {
      return convertRgbaToHexAndAlphaInt(rgbaObject);
    }
  }
  return null;
});
export const makeGetComputedMarginForNode = () => createSelector(getComputedStylesForNode, computedStyles => {
  if (!computedStyles) {
    return null;
  }
  const marginObject = {};
  ['top', 'bottom', 'left', 'right'].forEach(marginDirection => {
    marginObject[marginDirection] = parseFloat(computedStyles.get(`margin-${marginDirection}`));
  });
  return marginObject;
});
export const makeGetComputedPaddingForNode = () => createSelector(getComputedStylesForNode, computedStyles => {
  if (!computedStyles) {
    return null;
  }
  const paddingObject = {};
  ['top', 'bottom', 'left', 'right'].forEach(paddingDirection => {
    paddingObject[paddingDirection] = parseFloat(computedStyles.get(`padding-${paddingDirection}`));
  });
  return paddingObject;
});
export const makeGetComputedHiddenForNode = () => createSelector(getComputedStylesForNode, computedStyles => {
  if (!computedStyles) {
    return null;
  }
  if (computedStyles.get('display') === 'none') {
    return true;
  } else {
    return false;
  }
});
export const makeGetComputedMaxWidthForSelector = () => createSelector(getComputedStylesForSelector, computedStyles => {
  if (computedStyles) {
    const maxWidth = computedStyles.get('max-width');
    if (maxWidth === 'none') {
      return maxWidth;
    } else if (maxWidth) {
      return parseFloat(computedStyles.get(`max-width`));
    }
  }
  return null;
});
const makeProcessInpageNormalizedModule = (moduleId, builtInTypesByModuleId, customModuleIds, isUneditableModule) => {
  return createSelector(makeGetAnyModuleById(moduleId), module => {
    // Don't fail on things like fake modules, that will return as false-y values from makeGetAnyModuleById
    return module ? processModuleForIPEUI({
      builtInTypesByModuleId,
      customModuleIds: [],
      makeOverrideable: !isUneditableModule,
      module: module.toJS()
    }) : undefined;
  });
};

// Note, some excluded modules (e.g. fake ones) will be returned as undefined from
// makeProcessInpageNormalizedModule, that's why there is a `!!module` check
const shouldIncludeModuleInInpage = module => !!module && module.name && !INPAGE_MODULE_BLOCKLIST.includes(module.type);
const cachedGetInpageNormalizedModules = () => {
  let cachedModuleSelectors = {};
  const selectorFunc = createSelector(getBuiltInTypesByModuleId, getCustomModuleIds, getModuleLists, getModuleSchemas, getModules, getUneditableModules, getAuth, getIsUngatedForImmerModuleReducer, (builtInTypesByModuleId, customModuleIds, moduleLists, moduleSchemas, regularModules, uneditableModules, auth, isUngatedForImmerModuleReducer) => {
    const gateCheckedBuildFakeModulesSlice = isUngatedForImmerModuleReducer ? buildFakeModuleSliceImmer : buildFakeModuleSlice;
    const fakedState = Object.assign({}, gateCheckedBuildFakeModulesSlice(moduleLists, auth), buildFakeModuleSchemasSlice(moduleSchemas));
    const modulesByCategory = {
      regularModules: Object.values(regularModules),
      uneditableModules: Object.values(uneditableModules)
    };
    const nestedModuleArrays = Object.keys(modulesByCategory).map(filteredKey => {
      const modules = modulesByCategory[filteredKey];
      const isUneditableModule = filteredKey === 'uneditableModules';
      return modules.map(module => {
        const moduleName = module.get('name');

        // Early bail on missing module name (it would get filtered via shouldIncludeModuleInInpage
        // eventually anyway)
        if (!moduleName) {
          return undefined;
        }
        if (!cachedModuleSelectors[moduleName]) {
          cachedModuleSelectors[moduleName] = makeProcessInpageNormalizedModule(moduleName, builtInTypesByModuleId, customModuleIds, isUneditableModule);
        }
        const processedModule = cachedModuleSelectors[moduleName](fakedState);
        return shouldIncludeModuleInInpage(processedModule) ? processedModule : undefined;
      });
    });

    // Flatten (since `flatMap` isn't everywhere yet) and filter out undefined items
    return nestedModuleArrays.reduce((result, modules) => /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    result.concat(modules.filter(m => m !== undefined)), []);
  });

  // Reset method for testing
  selectorFunc._testOnlyResetCachedModuleSelectors = () => {
    cachedModuleSelectors = {};
  };
  return selectorFunc;
};
export const getInpageNormalizedModules = cachedGetInpageNormalizedModules();
export const getInpageModulesInsideFlexColumns = createSelector(getInpageNormalizedModules, modules => {
  return modules.filter(module => module.isInContainer);
});
export const getInpageModulesOutsideContainers = createSelector(getInpageNormalizedModules, getIsBlogPost, (modules, isBlogPost) => {
  const filteredModules = modules.filter(module => !module.isInContainer && !module.layout_section_id);
  if (!isBlogPost) {
    return filteredModules;
  }
  return filteredModules.sort((moduleA, moduleB) => {
    if (moduleA.name === 'post_body') {
      return 1;
    } else if (moduleB.name === 'post_body') {
      return -1;
    } else {
      return 0;
    }
  });
});
export const getInpageModulesInsideLayoutSections = createSelector(getInpageNormalizedModules, modules => {
  return modules.filter(module => module.layout_section_id);
});
export const getUseScrollIfModuleOutOfBoundsAtAll = createSelector([getInpageData], inpageData => inpageData.get('useScrollIfModuleOutOfBoundsAtAll'));
export const getNopeZoneSelector = createSelector([getInpageData], inpageData => inpageData.get('nopeZoneSelector'));