import { createSelector } from 'reselect';
import { requestFailed, requestSucceeded } from 'redux-request-state/util/requestStateCheckers';
import { APP_STATUS } from 'ContentEditorUI/redux/constants';
import { getContentSchemaRequest, getModuleSchemasRequest, getTemplateSchemaRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { getIsUserJitad } from './authSelectors';
import { getIsContentSafe } from './contentReadOnlyDataSelectors';
import { getIsAdvancedPageTemplateWithoutAccess } from './templateInfoSelectors';
import { getHasContentTypeLimitReducerAndExceededLimit } from './limitSelectors';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { getHasAnyContainerOrDndAreaToAddModules } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getIsABVariantWithoutAccess } from 'ContentEditorUI/redux/selectors/abTestSelectors';
// @ts-expect-error not typed
import { getIsMabVariantWithoutAccess } from 'ContentEditorUI/redux/selectors/mabExperimentSelectors';
import { getDisableCustomJitaUI, getTestReadOnly } from 'ContentEditorUI/redux/selectors/testFlagSelectors';
// @ts-expect-error not typed
import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
const getAppStates = basicSelector(state => {
  return state.appStatus;
});
export const createAppStatusSelector = key => createSelector([getAppStates], appStates => appStates[key]);
export const getIsContentSchemaFetched = createSelector([getContentSchemaRequest], contentSchemaRequest => requestSucceeded(contentSchemaRequest));
export const getAreModuleSchemasFetched = createSelector([getModuleSchemasRequest], moduleSchemasRequests => requestSucceeded(moduleSchemasRequests));
export const getIsI18nReady = createAppStatusSelector('i18nReady');
export const getCaresAboutNextIframeClick = createAppStatusSelector('caresAboutNextIframeClick');
export const getIframeReady = createAppStatusSelector('iframeReady');
export const getIframeRendered = createAppStatusSelector('iframeRendered');
export const getIframeHidden = createAppStatusSelector('iframeHidden');
export const getIframeStartedLoadingAt = createAppStatusSelector('iframeStartedLoadingAt');
export const getAddModuleSidebarLoaded = createAppStatusSelector('addModuleSidebarLoaded');
export const getHasManuallySetLoadingStatus = createAppStatusSelector('hasManuallySetLoadingStatus');
export const getIframeCaresAboutNextWindowClick = createAppStatusSelector('iframeCaresAboutNextWindowClick');
export const getHasEncounteredFatalError = createSelector([getContentSchemaRequest, getModuleSchemasRequest, getTemplateSchemaRequest], (contentSchemaRequest, moduleSchemasRequest, templateSchemaRequest) => requestFailed(contentSchemaRequest) || requestFailed(moduleSchemasRequest) || requestFailed(templateSchemaRequest));
export const getFatalError = createSelector([getContentSchemaRequest, getModuleSchemasRequest, getTemplateSchemaRequest, getHasEncounteredFatalError], (contentSchemaRequest, moduleSchemasRequest, templateSchemaRequest, hasEncounteredFatalError) => {
  if (hasEncounteredFatalError) {
    return contentSchemaRequest.fatalError || moduleSchemasRequest.fatalError || templateSchemaRequest.fatalError;
  }
  return null;
});
export const getIsContentTab = basicSelector(state => state.panesVisitStatus.currentPane === 'content');
export const appStatusFunction = (appReady, hasManuallySetLoadingStatus, hasEncounteredFatalError) => {
  if (hasEncounteredFatalError) {
    return APP_STATUS.FATAL_ERROR;
  }
  if (appReady && !hasManuallySetLoadingStatus) {
    return APP_STATUS.READY;
  }
  return APP_STATUS.LOADING;
};

// Iceberger uses the getAppIsReady, and getAppStatus functions in addition to having
// getAppIsReadyEmail, and getAppStatusEmail. Since iceberger doesn't use the
// getAreModuleSchemasFetched selector, we use this function to omit that check when
// iceberger is running.
const isIceberger = () => window.hubspot.bender.currentProject === 'EmailDragDropEditorUI';

// Scalable editor selector
export const getUniversalAppIsReady = createAppStatusSelector('isUniversalAppReady');

// TODO This selector powers a lot of stuff, it should probably be updated
// to work with "legacy" and "scalable editor" apps. Probably make a conditional
// to do one check versus the other (all true selectors for legacy, a subset
// that just checks for i18n or something + the "universalAppReady" redux state
export const getAppIsReady = createSelector([getIsContentSchemaFetched, getAreModuleSchemasFetched, getIsI18nReady, getIsContentSafe, getUniversalAppIsReady], (isContentSchemaFetched, isModuleSchemasFetched, isI18nReady, isContentSafe,
// This is used by scalable editors
universalAppIsReady) => {
  if (EditorConfigSingleton.getIsOnScalableEditor()) {
    return universalAppIsReady && isContentSafe && isI18nReady;
  } else {
    return isContentSchemaFetched && (isIceberger() || isModuleSchemasFetched) && isI18nReady && isContentSafe;
  }
});
export const createSelectorDeferredUntilAppReady = (selectors, funcs, fallback = {}) => {
  const selector = createSelector(selectors, funcs);
  return basicSelectorWithStats(state => getAppIsReady(state) ? selector(state) : fallback);
};
export const getAppStatus = createSelector([getAppIsReady, getHasManuallySetLoadingStatus, getHasEncounteredFatalError], appStatusFunction);

// Pages doesn't use the critsitReducer, should it?
export const getIsReadOnlyModeForCritsit = createSelector([state => state.critsit], critsit => !!critsit && critsit.isReadOnlyMode);
export const getIsReadOnlyMode = createSelector([getIsReadOnlyModeForCritsit, getIsAdvancedPageTemplateWithoutAccess, getHasContentTypeLimitReducerAndExceededLimit, getIsABVariantWithoutAccess, getIsMabVariantWithoutAccess, getTestReadOnly, getIsUserJitad, getDisableCustomJitaUI], (isReadOnlyModeForCritsit, isAdvancedTemplateWithoutAccess, hasExceededLimit, isABVariantWithoutAccess, isMabVariantWithoutAccess, isTestReadOnly, isUserJitad, disableCustomJitaUI) => {
  const showingCustomJitaUI = isUserJitad && !disableCustomJitaUI;
  return isReadOnlyModeForCritsit || isAdvancedTemplateWithoutAccess && !showingCustomJitaUI || hasExceededLimit || isABVariantWithoutAccess || isMabVariantWithoutAccess || isTestReadOnly;
});
export const getIsPublishDisabledForCritsit = createSelector([state => state.critsit], critsit => !!critsit && critsit.publishDisabled);
export const getIsExitPreviewAndLayoutButtonDisplayed = createSelector([getAppStates], appStates => appStates.exitPreviewAndLayoutButtonDisplayed);
export const getSaveError = createAppStatusSelector('saveError');
export const getRoutingInProgress = createAppStatusSelector('routingInProgress');
let appWasOnceReadyForSaving = false;
export const getIsAppReadyForSaving = createSelector([getAppIsReady, getIframeReady, getAddModuleSidebarLoaded, getHasAnyContainerOrDndAreaToAddModules], (parentAppIsReady, iframeReady, addModuleSidebarLoaded, needsAddModuleSidebar) => {
  if (appWasOnceReadyForSaving) {
    return true;
  }
  appWasOnceReadyForSaving = parentAppIsReady && iframeReady && (!needsAddModuleSidebar || needsAddModuleSidebar && addModuleSidebarLoaded);
  return appWasOnceReadyForSaving;
});
export const getIsInDeveloperMode = createAppStatusSelector('developerMode');