import { createSelector } from 'reselect';
import { createSelector as repackagedCreateSelector } from '../../types/createSelector';
import ContentRoutes from 'ContentUtils/Routes';
import DesignAssetTypes from 'ContentUtils/constants/DesignAssetTypes';
import { getLayoutRequest, getMarketerSectionsRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { requestUninitialized, requestStarted, requestSucceeded } from 'redux-request-state/util/requestStateCheckers';
import { getTemplatePath, getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
// @ts-expect-error no types yet
import templateErrorIsMissingTemplate from 'ContentEditorUI/utils/templateErrorIsMissingTemplate';
import { getIsLayoutReadOnly } from 'ContentEditorUI/redux/selectors/layoutSelectors';
import { getIsPage, getIsLandingPage, getIsSitePage, getIsBlogPost, getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import TemplateTypes from 'ContentUtils/constants/TemplateTypes';
import { getHasAdvancedLandingPagesWriteScope, getHasSitePagesWriteScope, getHasThemeGlobalSettingsScope, getHasBlogListingPagesWriteScope, getHasDndTemplateAccess, getHasCtaAccess } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getHasTheme, getHasThemeSettings } from 'ContentEditorUI/redux/selectors/themeSelectors';
import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import { captureMessage } from 'ContentEditorUI/lib/exceptions';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
// @ts-expect-error no types yet
import { getFeatureFlag } from 'ContentEditorUI/redux/selectors/featureFlagsSelectors';
import { FEATURE_FLAGS } from 'ContentEditorUI/redux/constants';
import { isTemplateInfoReceived } from '../reducers/templateInfoReducer';
// TEMPORARY MEASURE WHILE THESE TEMPLATE TYPES EXIST AND CANNOT BE CREATED FROM SCRATCH
const RESTORABLE_TEMPLATE_TYPES = ['unsubscribe_confirmation_email', 'resubscribe_email', 'resubscribe_confirmation_email', 'optin_email', 'optin_followup_email'];
const SIMPLE_TEMPLATE_TYPE = TemplateTypes.byName.simple_landing_page_template.id;
export const getTemplateInfo = basicSelector(state => state.templateInfo);
const createTemplateInfoSelector = templateProperty => {
  return createSelector([getTemplateInfo], templateInfo => {
    return isTemplateInfoReceived(templateInfo) ? templateInfo[templateProperty] : undefined;
  });
};
export const getTemplateLabel = createTemplateInfoSelector('label');
export const getTemplateAssetSource = createTemplateInfoSelector('assetSource');
export const getTemplatePreviewKey = createTemplateInfoSelector('previewKey');
export const getTemplateId = createTemplateInfoSelector('id');
export const getTemplateGeneratedFromLayoutId = createSelector([getTemplateInfo], templateInfo => {
  if (isTemplateInfoReceived(templateInfo) && templateInfo.generatedFromLayoutId) {
    return templateInfo.generatedFromLayoutId;
  }
  return undefined;
});
export const getIsRestorableTemplate = createSelector([getSubcategory], subcategory => RESTORABLE_TEMPLATE_TYPES.includes(subcategory));
export const getShouldRequestTemplateInfo = createSelector([getTemplatePath, getTemplateInfo], (templatePath, templateInfo) => !isTemplateInfoReceived(templateInfo) || templatePath !== templateInfo.path);
export const getTemplateIsFromLayout = createSelector([getTemplateInfo], templateInfo => {
  if (templateInfo && isTemplateInfoReceived(templateInfo)) {
    return templateInfo.isFromLayout;
  }
  return null;
});
export const getTemplatePathForDesignManager = createSelector([getTemplateIsFromLayout, getTemplateId, getTemplateGeneratedFromLayoutId], (templateIsFromLayout, templateId, generatedFromLayoutId) => {
  const designManagerInputs = templateIsFromLayout ? [generatedFromLayoutId, DesignAssetTypes.LAYOUT] : [templateId, DesignAssetTypes.TEMPLATE];
  return ContentRoutes.designManager(...designManagerInputs);
});
export const getTemplateLabelForSettings = createSelector([getTemplateIsFromLayout, getTemplatePath, getTemplateLabel], (isFromLayout, templatePath, templateLabel) => {
  if (isFromLayout) {
    return templateLabel;
  }
  if (!templatePath) return '';
  const parts = templatePath.split('/');
  return parts[parts.length - 1];
});
export const getTemplateThumbnailPath = createSelector([getTemplateInfo], templateInfo => templateInfo.userMeta && templateInfo.userMeta.thumbnailPath);
export const getEditTemplateUrl = createSelector([getTemplateGeneratedFromLayoutId, getTemplateId, getTemplatePath, getTemplateAssetSource], (generatedFromLayoutId, templateId, templatePath, templateAssetSource) => {
  if (generatedFromLayoutId) {
    return ContentRoutes.designManager(generatedFromLayoutId, DesignAssetTypes.LAYOUT);
  } else if (templateId) {
    return ContentRoutes.designManager(templateId, DesignAssetTypes.TEMPLATE, templateAssetSource, templatePath);
  } else if (templatePath) {
    return `${ContentRoutes.designManager()}?templatePath=${templatePath}`;
  }
  captureMessage('Unable to create an edit template url');
  return null;
});

// the layout request isn't needed if the template isn't generated from a layout
export const getIsLayoutRequestSucceededIfNeeded = createSelector([getTemplateGeneratedFromLayoutId, getTemplateIsFromLayout, getLayoutRequest], (generatedFromLayoutId, isFromLayout, layoutRequest) => !(generatedFromLayoutId || isFromLayout) || requestSucceeded(layoutRequest));

// Selectors brought over from contentSchemaSelectors that need to use
// new reducers to get just the data you need
export const getSchema = basicSelector(state => state.contentSchema.schema);
export const hasTemplateErrors = createSelector([getSchema], schema => schema.template_errors.length > 0);
export const getTemplateErrors = createSelector([getSchema], schema => schema ? schema.template_errors : []);
export const getHasTemplate = createSelector([getTemplateErrors, getTemplatePath], (templateErrors, templatePath) => {
  return templatePath && !templateErrors.some(templateErrorIsMissingTemplate(templatePath));
});
export const getHasLayout = createSelector(getTemplateGeneratedFromLayoutId, templateGeneratedFromLayoutId => !!templateGeneratedFromLayoutId);
export const getIsTemplateReadOnly = repackagedCreateSelector([getTemplateInfo, getHasLayout, getIsLayoutReadOnly], (templateInfo, hasLayout, isLayoutReadOnly) => {
  const isReadOnlyLayout = hasLayout && isLayoutReadOnly;
  const isReadOnlyTemplate = !hasLayout && isTemplateInfoReceived(templateInfo) && templateInfo.isReadOnly;
  return !!(isReadOnlyTemplate || isReadOnlyLayout);
});

// generatedFromLayoutId -- shows that a template has a layout
// requestPending -- shows that the layout request is in progress
// this returns true when we actually have a layout to fetch, but haven't finished yet
export const getIsLayoutRequestPending = createSelector([getTemplateGeneratedFromLayoutId, getLayoutRequest], (generatedFromLayoutId, layoutRequest) => {
  const requestPending = requestUninitialized(layoutRequest) || requestStarted(layoutRequest);
  return !!(generatedFromLayoutId && requestPending);
});
export const getIsEditTemplateButtonDisabled = createSelector([getIsLayoutRequestPending, getIsTemplateReadOnly, getHasTheme], (isLayoutRequestPending, isTemplateReadOnly, hasTheme) => {
  return isLayoutRequestPending || isTemplateReadOnly && !hasTheme;
});

// TODO, rename "advanced" to non-Starter
export const getIsAdvancedPageTemplate = createSelector([getTemplateInfo, getIsPage], (templateInfo, isPage) => !!(isPage && templateInfo && templateInfo.templateType && templateInfo.templateType !== SIMPLE_TEMPLATE_TYPE));
export const getIsStarterPageTemplate = createSelector([getTemplateInfo, getIsPage], (templateInfo, isPage) => !!(isPage && templateInfo && templateInfo.templateType && templateInfo.templateType === SIMPLE_TEMPLATE_TYPE));
export const getIsAdvancedPageTemplateOrHasAdvancedFeaturesAccess = createSelector([getIsAdvancedPageTemplate, getHasAdvancedLandingPagesWriteScope, getHasSitePagesWriteScope], (isAdvancedPageTemplate, hasAdvancedLandingPagesWriteScope, hasSitePagesWriteScope) => isAdvancedPageTemplate || hasAdvancedLandingPagesWriteScope || hasSitePagesWriteScope);
export const getIsStarterPageTemplateAndNoAdvancedFeaturesAccess = createSelector([getIsAdvancedPageTemplate, getHasAdvancedLandingPagesWriteScope, getHasSitePagesWriteScope], (isAdvancedPageTemplate, hasAdvancedLandingPagesWriteScope, hasSitePagesWriteScope) => !isAdvancedPageTemplate && !hasAdvancedLandingPagesWriteScope && !hasSitePagesWriteScope);
const getShouldDefaultToAdvancedTemplateType = () => !getFeatureFlag(FEATURE_FLAGS.templateTypes);
export const getHasAdvancedTemplateAccessForThisPageType = createSelector([getHasAdvancedLandingPagesWriteScope, getIsLandingPage, getHasSitePagesWriteScope, getIsSitePage, getHasBlogListingPagesWriteScope, getIsBlogListingPage], (hasAdvancedLandingPagesWriteScope, isLandingPage, hasSitePagesWriteScope, isSitePage, hasBlogListingPagesWriteScope, isBlogListingPage) => hasAdvancedLandingPagesWriteScope && isLandingPage || hasSitePagesWriteScope && isSitePage || hasBlogListingPagesWriteScope && isBlogListingPage);
export const getIsAdvancedPageTemplateWithAccess = createSelector([getIsAdvancedPageTemplate, getHasAdvancedTemplateAccessForThisPageType, getShouldDefaultToAdvancedTemplateType], (isAdvancedPageTemplate, hasAdvancedTemplateAccessForThisPageType, shouldDefaultToAdvancedTemplateType) => isAdvancedPageTemplate && hasAdvancedTemplateAccessForThisPageType || shouldDefaultToAdvancedTemplateType);
export const getIsAdvancedPageTemplateWithoutAccess = createSelector([getIsAdvancedPageTemplate, getHasAdvancedTemplateAccessForThisPageType], (isAdvancedPageTemplate, hasAdvancedTemplateAccessForThisPageType) => isAdvancedPageTemplate && !hasAdvancedTemplateAccessForThisPageType);
export const getIsAdvancedPageTemplateWithoutCtaAccess = createSelector([getIsAdvancedPageTemplate, getHasCtaAccess], (isAdvancedPageTemplate, hasCtaAccess) => isAdvancedPageTemplate && !hasCtaAccess);
export const getIsBlogPostWithoutCtaAccess = createSelector([getIsBlogPost, getHasCtaAccess], (isBlogPost, hasCtaAccess) => isBlogPost && !hasCtaAccess);
export const getTemplatePreviewUrl = createSelector([getTemplatePath, getTemplatePreviewKey], (templatePath, templatePreviewKey) => {
  const env = enviro.getShort() === 'qa' ? 'qa' : '';
  const previewKey = templatePreviewKey ? `&hs_preview_key=${templatePreviewKey}` : '';
  return `//preview${env}.hs-sites.com/_hcms/preview/template/multi?template_file_path=${encodeURIComponent(templatePath)}&portalId=${PortalIdParser.get()}${previewKey}`;
});
export const getHasDesignTabPermissions = createSelector([getIsAdvancedPageTemplate, getHasThemeGlobalSettingsScope], (isAdvancedPageTemplate, hasThemeGlobalSettingsScope) => {
  return !isAdvancedPageTemplate || hasThemeGlobalSettingsScope;
});
export const getDesignTabEnabled = createSelector([getHasThemeSettings, getHasDesignTabPermissions], (hasThemeSettings, hasDesignTabPermissions) => {
  return hasThemeSettings && hasDesignTabPermissions;
});
export const getIsTemplateFromLayoutWithoutAccess = createSelector([getHasDndTemplateAccess, getTemplateIsFromLayout], (hasDndTemplateAccess, isTemplateFromLayout) => !hasDndTemplateAccess && isTemplateFromLayout);
export const getAddableSections = createSelector([getTemplateInfo], templateInfo => templateInfo.customSections || []);
export const getAddableEmbeds = createSelector([getTemplateInfo],
// Used to differentiate draggable sections in `InpageThemeSectionsContainer`, `InpageThemelessSectionsContainer`,
// and likely `InpageSavedSectionsContainer` in the future
templateInfo => (templateInfo.embeds || []).map(embed => Object.assign({}, embed, {
  isEmbed: true
})));
const getThemeEmbeds = createSelector([getAddableEmbeds], addableEmbeds => addableEmbeds.filter(embed => embed.themePath));
const getThemelessEmbeds = createSelector([getAddableEmbeds], addableEmbeds => addableEmbeds.filter(embed => !embed.themePath));
export const getThemelessSections = createSelector([getThemelessEmbeds], themelessEmbeds => themelessEmbeds);
export const getMarketerSectionsCount = createSelector([getTemplateInfo], templateInfo => templateInfo.marketerSectionsCount);
export const getMarketerSectionsLimit = createSelector([getTemplateInfo], templateInfo => templateInfo.marketerSectionsLimit);
export const getShouldRequestMarketerSections = createSelector([getMarketerSectionsRequest, getHasTheme, getMarketerSectionsCount], (marketerSectionsRequest, hasTheme, marketerSectionsCount) => {
  return hasTheme && requestUninitialized(marketerSectionsRequest) && marketerSectionsCount < 0;
});
export const getPreviousInsertedSectionTree = createSelector([getTemplateInfo], templateInfo => templateInfo.previousInsertedSectionTree);
const getDeveloperSections = createSelector([getAddableSections], addableSections => {
  return addableSections.filter(section => !section.marketerSection);
});
export const getThemeSections = createSelector([getDeveloperSections, getThemeEmbeds], (developerSections, themeEmbeds) => {
  return [...developerSections, ...themeEmbeds];
});
const getMarketerSections = createSelector([getAddableSections], addableSections => {
  return addableSections.filter(section => section.marketerSection);
});
export const getSavedSections = createSelector([getMarketerSections], marketerSections => {
  return marketerSections;
});
export const getCanUseCustomSections = basicSelectorWithStats(state => {
  const hasTheme = getHasTheme(state);
  const sectionsEnabled = getFeatureFlag(FEATURE_FLAGS.sections);
  return Boolean(hasTheme && sectionsEnabled);
});
const defaultMarketerSectionLimitInfo = {
  isOverLimit: false,
  isNearLimit: false,
  isAtLimit: false
};
const MARKETER_SECTION_LIMIT_ALERT_THRESHOLD = 2;
export const getMarketerSectionsLimitInfo = createSelector([getCanUseCustomSections, getMarketerSectionsCount, getMarketerSectionsLimit], (canUseCustomSections, marketerSectionsCount, marketerSectionsLimit) => {
  if (!canUseCustomSections) {
    return defaultMarketerSectionLimitInfo;
  }
  if (marketerSectionsLimit < 0) {
    // still waiting on the request, return default value for now
    return defaultMarketerSectionLimitInfo;
  }
  const marketerSectionsLimitDifference = marketerSectionsLimit - marketerSectionsCount;
  return {
    isOverLimit: marketerSectionsLimitDifference < 0,
    isNearLimit: marketerSectionsLimitDifference > 0 && marketerSectionsLimitDifference <= MARKETER_SECTION_LIMIT_ALERT_THRESHOLD,
    isAtLimit: marketerSectionsLimitDifference === 0
  };
});