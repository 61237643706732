import produce from 'immer';
import { setDeepValue } from 'ContentUtils/helpers/ObjectHelpers';
import { ScpModuleInsertionKeys } from '../constants/moduleInsertion';
const OmittedModuleSchemaKeys = ['module_id', 'no_wrapper', 'schema_version', 'tag'];
export function getParentScpProperty(richTextValues, moduleId) {
  return Object.keys(richTextValues).find(richTextProperty => {
    const propertyValue = richTextValues[richTextProperty];
    return Boolean(moduleId && propertyValue[moduleId]);
  });
}
function extractOverridesFromModule(moduleSchemaBody) {
  return Object.fromEntries(Object.entries(moduleSchemaBody).filter(([key]) => !OmittedModuleSchemaKeys.includes(key)));
}
export function mergeModulesIntoRichText(richTextValues, rootPropertyName, moduleSchemas) {
  const newRichTextValues = produce(richTextValues, draft => {
    // draft can be null
    draft = draft || {};
    moduleSchemas.forEach(schema => {
      //schema id will always be defined but include fallback to name to be safe
      draft = setDeepValue(draft, [rootPropertyName, schema.id], {
        [ScpModuleInsertionKeys.HS_MODULE_SCHEMA]: schema,
        [ScpModuleInsertionKeys.HS_FIELD_OVERRIDES]: extractOverridesFromModule(schema.body)
      });
    });
    return draft;
  });
  return newRichTextValues;
}
export function removeModulesInRichText(rootPropertyName, modulesInRichText, modulesToBeRemoved) {
  const updatedModulesInRichText = produce(modulesInRichText, draft => {
    // draft can be null
    draft = draft || {};
    modulesToBeRemoved.forEach(schema => {
      delete draft[rootPropertyName][schema.name];
    });
    return draft;
  });
  return updatedModulesInRichText;
}