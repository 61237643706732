import { createSelector } from 'reselect';
import { createSelector as repackagedCreateSelector } from '../../types/createSelector';
// @ts-expect-error not typed yet
import { normalizeModuleErrorTokens } from 'ContentEditorUI/utils/moduleErrorUtils';
import { getAllModuleSchemasAsArray, getModuleIdsByBuiltinType, getSchemaForModuleHelper } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getModules } from 'ContentEditorUI/redux/selectors/moduleSelectors';
// @ts-expect-error not typed yet
import { findModuleById } from 'ContentEditorUI/data/moduleUtils';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getImmutableOrPlain, isImmutable } from '../../utils/dataHelpers';
const ID_MATCHER = /^((widget|module)_*)(\d*)/;
const parseModuleId = id => {
  if (ID_MATCHER.test(id)) {
    return ID_MATCHER.exec(id)[3];
  }
  return id;
};
const convertErrorTokensToObject = error => {
  if (isImmutable(error)) {
    return error.get('errorTokens').map(v => v.get(0)).toJS();
  }
  return Object.fromEntries(Object.entries(error.errorTokens).map(([key, v]) => [key, v[0]]));
};
const moduleIdForError = error => convertErrorTokensToObject(error).id;
const getRawModuleErrors = basicSelector(state => state.moduleErrors);
export const getResources = basicSelector(state => state.resources);
const getModulesWithErrors = createSelector([getRawModuleErrors], moduleErrors => {
  return new Set(moduleErrors.filter(error => {
    var _error$errorTokens;
    return (_error$errorTokens = error.errorTokens) === null || _error$errorTokens === void 0 || (_error$errorTokens = _error$errorTokens.id) === null || _error$errorTokens === void 0 ? void 0 : _error$errorTokens[0];
  }).map(error => parseModuleId(error.errorTokens.id[0])));
});
export const getModuleHasErrors = basicSelectorWithStats((state, module) => {
  return getModulesWithErrors(state).has(
  // @ts-expect-error still finalizing central module type
  parseModuleId(`${module.get('id')}`));
});
export const makeDataValidationErrors = modulesErrorsSelector => createSelector([modulesErrorsSelector, getResources, getModules, getAllModuleSchemasAsArray, getModuleIdsByBuiltinType, getCategoryId], (moduleErrors, resources, modules, allModuleSchemasArray, moduleIdsByBuiltinType, categoryId) => {
  return moduleErrors.reduce((result, error) => {
    const tokens = convertErrorTokensToObject(error);
    const module = findModuleById(modules, tokens.id);
    if (!module) {
      // module has been deleted since validation call or is "orphaned" (has a deleted_at property)
      return result;
    }
    const schema = getSchemaForModuleHelper(module, allModuleSchemasArray, moduleIdsByBuiltinType, categoryId);
    const errorTokens = normalizeModuleErrorTokens(module, schema, tokens, resources);
    if (errorTokens) {
      result[`moduleData.${errorTokens.id}.${errorTokens.path}`] = {
        pane: 'content',
        type: 'moduleDataValidation',
        errorType: getImmutableOrPlain(error, 'errorType'),
        errorTokens,
        message: getImmutableOrPlain(error, 'message')
      };
    }
    return result;
  }, {});
});
export const getDataValidationErrors = makeDataValidationErrors(getRawModuleErrors);
export const getHasAnyModulesWithErrors = repackagedCreateSelector(getRawModuleErrors, getModules, (moduleErrors, modules) => {
  return moduleErrors.some(error => {
    // The modules map already omits modules that are orphaned (deleted_at) or in orphaned containers
    return Boolean(findModuleById(modules, moduleIdForError(error)));
  });
});