import isEqual from 'hs-lodash/isEqual';
import get from 'hs-lodash/get';
import lodashHas from 'hs-lodash/has';
import update from 'hs-lodash/update';
import produce, { isDraft } from 'immer';
import set from '../types/set';
import enviro from 'enviro';
const uniqueIdCache = new WeakMap();
let nextId = 1; // skipping 0 so all IDs are truthy
function generateUniqueId() {
  return nextId++;
}

/**
 * Generates and caches (in a memory-safe manner) a unique ID for a given object
 * by its reference. Useful for factoring the uniqueness of an object into a
 * memoization key string or react key.
 *
 * Throws an error for primitives like null, undefined, and any string, number,
 * or boolean. Supports only Objects, Arrays, Functions, and Regexes.
 */
function uniqueIdForObject(object) {
  let id = uniqueIdCache.get(object);
  if (id === undefined) {
    id = generateUniqueId();
    uniqueIdCache.set(object, id);
  }
  return id;
}
const getWithKeyPath = get;
function bindOptionalContext(predicate, context) {
  if (!context) {
    return predicate;
  } else {
    return predicate.bind(context);
  }
}
function shallowCopyObjectWithPrototype(obj, propertyDescriptors) {
  return Object.create(Object.getPrototypeOf(obj), propertyDescriptors);
}
export class BaseMockImmutableObject {
  equals(that) {
    this.logUsage('equals');
    return isEqual(this, that);
  }
  every(predicate, context) {
    this.logUsage('every');
    const entries = Object.entries(this);
    const boundPredicate = bindOptionalContext(predicate, context);
    return entries.every(([key, value]) => boundPredicate(value, key, this));
  }

  // should we support this one?
  flatten(__depth) {
    this.logUsage('flatten');
  }
  forEach(predicate, context) {
    this.logUsage('forEach');
    const entries = Object.entries(this);
    const boundPredicate = bindOptionalContext(predicate, context);
    let numIterations = 0;
    entries.every(([key, value]) => {
      numIterations += 1;
      return boundPredicate(value, key, this) !== false;
    });
    return numIterations;
  }
  filter(predicate, context) {
    this.logUsage('filter');
    const boundPredicate = bindOptionalContext(predicate, context);
    const entries = Object.entries(this);
    const propertyDesriptors = Object.getOwnPropertyDescriptors(this);
    entries.forEach(([key, value]) => {
      if (!boundPredicate(value, key, this)) {
        delete propertyDesriptors[key];
      }
    });
    return shallowCopyObjectWithPrototype(this, propertyDesriptors);
  }
  get(key, notSetValue) {
    this.logUsage('get');
    if (notSetValue !== undefined && !this.has(key)) {
      return notSetValue;
    } else {
      return this[key];
    }
  }
  getIn(keyPath, notSetValue) {
    this.logUsage('getIn');
    if (notSetValue !== undefined && !this.hasIn(keyPath)) {
      return notSetValue;
    } else {
      return getWithKeyPath(this, keyPath);
    }
  }
  has(key) {
    this.logUsage('has');
    return Object.hasOwnProperty.call(this, key);
  }
  hasIn(keyPath) {
    this.logUsage('hasIn');
    return lodashHas(this, keyPath);
  }
  hashCode() {
    this.logUsage('hashCode');
    return uniqueIdForObject(this);
  }
  map(mapper, context) {
    this.logUsage('map');
    const boundMapper = bindOptionalContext(mapper, context);
    const entries = Object.entries(this);
    const propertyDesriptors = Object.getOwnPropertyDescriptors(this);
    entries.forEach(([key, value]) => {
      propertyDesriptors[key].value = boundMapper(value, key, this);
    });
    return shallowCopyObjectWithPrototype(this, propertyDesriptors);
  }
  reduce(reducer, initialReduction, context) {
    this.logUsage('reduce');
    const entries = Object.entries(this);
    const boundReducer = bindOptionalContext(reducer, context);
    if (arguments.length < 2) {
      return entries.reduce(([, reduction], [key, value]) => [key, boundReducer(reduction, value, key, this)])[1];
    } else {
      return entries.reduce((reduction, [key, value]) => boundReducer(reduction, value, key, this), initialReduction);
    }
  }
  set(key, value) {
    this.logUsage('set');
    if (isDraft(this)) {
      this[key] = value;
      return this;
    }
    return produce(this, draft => {
      draft[key] = value;
    });
  }
  setIn(keyPath, value) {
    this.logUsage('setIn');
    if (isDraft(this)) {
      set(this, keyPath, value);
      return this;
    }
    return produce(this, draft => {
      set(draft, keyPath, value);
    });
  }
  some(predicate, context) {
    this.logUsage('some');
    const entries = Object.entries(this);
    const boundPredicate = bindOptionalContext(predicate, context);
    return entries.some(([key, value]) => boundPredicate(value, key, this));
  }
  sort(comparator) {
    this.logUsage('sort');
    produce(this, __ => {
      return Object.fromEntries(Object.entries(this).sort(([__keyA, valueA], [__keyB, valueB]) => {
        if (comparator) {
          return comparator(valueA, valueB);
        }
        return valueA - valueB;
      }));
    });
  }
  sortBy(comparatorValueMapper, comparator) {
    this.logUsage('sortBy');
    produce(this, __ => {
      return Object.fromEntries(Object.entries(this).sort(([keyA, valueA], [keyB, valueB]) => {
        const valueAToCompare = comparatorValueMapper(valueA, keyA, this);
        const valueBToCompare = comparatorValueMapper(valueB, keyB, this);
        if (comparator) {
          return comparator(valueAToCompare, valueBToCompare);
        }
        return valueAToCompare - valueBToCompare;
      }));
    });
  }
  update(...args) {
    this.logUsage('update');
    if (args.length === 1) {
      const [updater] = args;
      // @ts-expect-error this passes for immutable-less?
      return produce(this, updater);
    } else {
      let key;
      let updater;
      let currentValue;
      if (args.length === 2) {
        [key, updater] = args;
        currentValue = this[key];
      } else {
        [key, currentValue, updater] = args;
        currentValue = this[key];
      }
      return this.set(key, updater(currentValue));
    }
  }
  updateIn(...args) {
    this.logUsage('updateIn');
    if (args.length === 2) {
      const [keyPath, updater] = args;
      return produce(this, draft => update(draft, keyPath, updater));
    } else {
      const [keyPath, notSetValue, updater] = args;
      if (!this.hasIn(keyPath)) {
        return produce(this, draft => update(draft, keyPath, () => updater(notSetValue)));
      } else {
        return produce(this, draft => update(draft, keyPath, updater));
      }
    }
  }
  toJS() {
    this.logUsage('toJS');
    return this.toJSON();
  }
  withMutations(mutator) {
    this.logUsage('withMutations');
    return produce(this, mutator);
  }
  logUsage(methodName) {
    if (!enviro.deployed()) {
      const usageError = new Error(`Unexpected usage of \`${methodName}\` in ${this.getBaseName()} mock immutable`);
      console.error(usageError);
    }
  }
  getBaseName() {
    return 'unknown';
  }
}

// strips the `abstract` modifiers from the methods while preserving the `this` type

// This is something that `immutable-less` does to log each
// time an unsupported Immutable method is called. Should we
// do the same? Or maybe should we create an Immutable using the own properties of the object
// to try to gracefully fallback?

// ImmutableJSRecordMethodNames.forEach((methodName) => {
//   if (
//     !Object.prototype.hasOwnProperty.call(
//       BaseMockImmutableObject.prototype,
//       methodName
//     )
//   ) {
//     // @ts-expect-error normally these would be tacked on to BaseMockImmutableObject's
//     // type but this fallback is specifcally for code which is not typed
//     BaseMockImmutableObject.prototype[methodName] = function () {
//       const error = new Error(
//         `Attempted to call unimplemented method '${methodName}()' on an ImmutableObject`
//       );
//       // @ts-expect-error newrelic isn't declared on Window
//       const newrelic = window.newrelic;
//       if (newrelic && newrelic.noticeError) {
//         newrelic.noticeError(error);
//       }

//       Raven.captureException(error);
//     };
//   }
// });