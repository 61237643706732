'use es6';

import Raven from 'raven-js';
import { ADDED_MODULE, ADDED_MODULES_TO_FAKE_MODULES, ADDED_MODULES_TO_POST_BODY, ADDED_CUSTOM_UPDATES_FOR_FAKE_MODULES, DELETED_MODULE, CLOSE_DELETE_REQUEST_MODAL, REQUESTED_MODULE_DELETE, MODULE_SET_LAYOUT_STYLES, MODULE_RESET_LAYOUT_STYLES, LAYOUT_SECTION_MODULE_DELETED, LAYOUT_SECTION_ROW_DELETED, LAYOUT_SECTION_COLUMN_DELETED, LAYOUT_SECTION_SET_CELL_CSS_ID, LAYOUT_SECTION_SET_CELL_CSS_STYLES, LAYOUT_SECTION_SET_CELL_CSS_CLASS, LAYOUT_SECTION_SET_ROW_CSS_CLASS, LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES, LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES, LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES, LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES, SET_BREAKPOINT_STYLES_FOR_TREE_NODE, MOBILE_LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES, MOBILE_LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES, LAYOUT_SECTION_ROW_SET_VERTICAL_ALIGNMENT, LAYOUT_SECTION_ADD_CLASS_TO_CELL, LAYOUT_SECTION_REMOVE_CLASS_FROM_CELL, MOVED_MODULE, SAVED_MODULE, UPDATED_CONTAINERS_FROM_PREVIEW, SET_MODULE_BODY, MERGE_MODULE_BODY, MERGE_MODULE_DATA, MERGE_MODULE_META_DATA, EDIT_FORM_FOLLOWUP_EMAIL, SET_MODULE_SMART_OBJECT_BODY, MERGE_MODULE_SMART_OBJECT_BODY, SET_MODULE_STYLES, REMOVE_FAKE_BODY_MODULE_STYLES, UPDATED_MODULE_SMART_RULES, REMOVED_MODULE_SMART_RULES, RESYNCHRONIZE_MODULE_SMART_OBJECTS, LAYOUT_SECTION_CELL_CLONED_TO_RIGHT, LAYOUT_SECTION_ROW_CLONED_BELOW, CLOSE_CLONE_REQUEST_MODAL, LAYOUT_SECTION_REQUESTED_CLONE, SET_DEPRECATED_STYLES_FLAGS, SET_MODULE_BREAKPOINT_STYLES, CUSTOM_LAYOUT_SECTION_ADDED, CLEAR_SCROLL_TO_NODE_POSITION_INFO, UPDATE_STATIC_MODULE_WITH_NEW_ID, LAYOUT_SECTION_SET_LABEL, RESET_LAYOUT_SECTION_TO_TEMPLATE_DEFAULT, SET_ADD_SECTION_MISSING_MODULE_ERRORS, UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO, UPDATE_POST_BODY, ADD_NEW_EMBED_TO_EMBED_INFO, REMOVE_EMBED_FROM_EMBED_INFO } from 'ContentEditorUI/redux/actions/actionTypes';
import { LAYOUT_SECTION_ADD_TEMPORARY_OVERLAY, LAYOUT_SECTION_REMOVE_TEMPORARY_OVERLAY } from 'ContentEditorUI/redux/actions/inpageReduxActionTypes';
import { extractScpPropertiesFromModule } from 'structured-content-lib/utils/transformers';
import { selectTableAsModuleSchema, selectInstanceValues } from 'structured-content-lib/redux/selectors/instanceEditSelectors';
import { addModulesToRichTextProperty, deleteModulesInRichTextProperty } from 'structured-content-lib/redux/actions/instanceEditActions';
import ModuleRender from 'ContentEditorUI/api/ModuleRender';
import { getModuleById, getCellOrRowById, getDefaultType, getDefaultTypeHelper, getFakePostBodyModule, getFakePostBodyHTML } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getCurrentLayoutSectionToAddTo } from 'ContentEditorUI/redux/selectors/sectionsSelectors';
import { getBuiltInTypesByModuleId } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getRenderLanguage } from 'ContentEditorUI/redux/selectors/languageSelectors';
import { createNewModuleSchemaJson } from 'ContentUtils/helpers/ModuleCreationHelpers';
import { addStaticSectionLayoutDataToMockTree, hydrateCustomSectionIntoLayoutTree, hydratePreviousSectionTreeIntoLayoutTreeWithoutMissingModuleSchemas } from '../../utils/layoutSectionTreeUtils';
import { clearSelectedItem, updateSelectedItem } from 'ContentEditorUI/redux/actions/selectionActions';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { getPreviousInsertedSectionTree } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import I18n from 'I18n';
import { makeHublModuleElementManager } from 'tinymce-plugins/hsmoduleinsertion/utils/hubl';
import { getIsStaticSectionFromValue } from 'layout-data-lib/LayoutDataTree/helpers';
import { getIsUngatedForFixedLayoutSections } from '../selectors/authSelectors';
import { maybeToJS } from '../../utils/dataHelpers';
export const addModuleToFlexColumn = (module, containerId, index, schema) => dispatch => {
  if (schema && !schema.global) {
    dispatch(updateSelectedItem(module.name));
  }
  return dispatch({
    type: ADDED_MODULE,
    payload: {
      module,
      containerId,
      index
    }
  });
};
export const addModulesToFakeModules = modules => ({
  type: ADDED_MODULES_TO_FAKE_MODULES,
  payload: {
    modules
  }
});
export const addCustomUpdatesForFakeModules = customUpdates => ({
  type: ADDED_CUSTOM_UPDATES_FOR_FAKE_MODULES,
  payload: {
    customUpdates
  }
});
export const requestModuleDelete = moduleId => ({
  type: REQUESTED_MODULE_DELETE,
  payload: {
    moduleId
  }
});
export const deleteModule = moduleId => dispatch => {
  dispatch(clearSelectedItem({
    onlyIfSelected: moduleId
  }));
  dispatch({
    type: DELETED_MODULE,
    payload: {
      id: moduleId
    }
  });
};
export const moveModule = (movingModuleId, newIndex, newColumnId, oldColumnId) => ({
  type: MOVED_MODULE,
  payload: {
    movingModuleId,
    newIndex,
    newColumnId,
    oldColumnId
  }
});
export const closeDeleteRequestModal = layoutSectionId => ({
  type: CLOSE_DELETE_REQUEST_MODAL,
  payload: {
    layoutSectionId
  }
});
export const addModuleToLayoutSection = ({
  addAction,
  module,
  schema
}) => (dispatch, getState) => {
  const reduxState = getState();
  const pageRenderLanguage = getRenderLanguage(reduxState);
  const builtInTypesByModuleId = getBuiltInTypesByModuleId(reduxState);
  return ModuleRender.render({
    data: {
      type: module.type,
      name: module.name,
      body: module.body,
      language: pageRenderLanguage
    }
  }).then(resp => {
    addAction.payload.newModuleSchemaJson = createNewModuleSchemaJson(schema, module);
    addAction.payload.newModuleType = getDefaultTypeHelper(module, schema, builtInTypesByModuleId);
    if (schema && !schema.global) {
      dispatch(updateSelectedItem(module.name));
    }
    dispatch(addAction);
    return resp;
  });
};
export const deleteModuleFromLayoutSection = (moduleId, layoutSectionId) => dispatch => {
  dispatch(clearSelectedItem({
    onlyIfSelected: moduleId
  }));
  dispatch({
    type: LAYOUT_SECTION_MODULE_DELETED,
    payload: {
      moduleId,
      layoutSectionId
    }
  });
};
export const deleteRowFromLayoutSection = (rowId, layoutSectionId) => ({
  type: LAYOUT_SECTION_ROW_DELETED,
  payload: {
    rowId,
    layoutSectionId
  }
});
export const deleteColumnFromLayoutSection = (columnId, layoutSectionId) => ({
  type: LAYOUT_SECTION_COLUMN_DELETED,
  payload: {
    columnId,
    layoutSectionId
  }
});
export const setCellLayoutStyles = (layoutSectionId, cellId, newLayoutStyles) => ({
  type: LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES,
  payload: {
    layoutSectionId,
    cellId,
    newLayoutStyles
  }
});
export const setRowLayoutStyles = (layoutSectionId, rowId, newLayoutStyles) => ({
  type: LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES,
  payload: {
    layoutSectionId,
    rowId,
    newLayoutStyles
  }
});
export const setBreakpointStylesForTreeNode = (layoutSectionId, nodeId, newLayoutStyles, breakpoint) => ({
  type: SET_BREAKPOINT_STYLES_FOR_TREE_NODE,
  payload: {
    layoutSectionId,
    nodeId,
    newLayoutStyles,
    breakpoint
  }
});
export const setModuleLayoutStyles = ({
  moduleName,
  layoutSectionId,
  newLayoutStyles
}) => ({
  type: MODULE_SET_LAYOUT_STYLES,
  payload: {
    moduleName,
    newLayoutStyles,
    layoutSectionId,
    // optional
    cellId: moduleName
  }
});
export const resetCellLayoutStyles = ({
  cellId,
  layoutSectionId
}) => ({
  type: LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES,
  payload: {
    layoutSectionId,
    cellId
  }
});
export const resetMobileCellLayoutStyles = ({
  cellId,
  layoutSectionId
}) => ({
  type: MOBILE_LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES,
  payload: {
    layoutSectionId,
    cellId
  }
});
export const resetRowLayoutStyles = ({
  rowId,
  layoutSectionId
}) => ({
  type: LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES,
  payload: {
    layoutSectionId,
    rowId
  }
});
export const resetMobileRowLayoutStyles = ({
  rowId,
  layoutSectionId
}) => ({
  type: MOBILE_LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES,
  payload: {
    layoutSectionId,
    rowId
  }
});
export const resetModuleLayoutStyles = ({
  moduleName,
  layoutSectionId
}) => ({
  type: MODULE_RESET_LAYOUT_STYLES,
  payload: {
    moduleName,
    layoutSectionId,
    // optional
    cellId: moduleName
  }
});
export const setRowVerticalAlignment = (layoutSectionId, rowId, verticalAlignment) => ({
  type: LAYOUT_SECTION_ROW_SET_VERTICAL_ALIGNMENT,
  payload: {
    layoutSectionId,
    rowId,
    verticalAlignment
  }
});
export const setCellCssId = (layoutSectionId, cellId, cssId) => ({
  type: LAYOUT_SECTION_SET_CELL_CSS_ID,
  payload: {
    layoutSectionId,
    cellId,
    cssId
  }
});
export const setCellCssStyles = (layoutSectionId, cellId, cssStyles) => ({
  type: LAYOUT_SECTION_SET_CELL_CSS_STYLES,
  payload: {
    layoutSectionId,
    cellId,
    cssStyles
  }
});
export const setCellCssClass = (layoutSectionId, cellId, cssClass) => ({
  type: LAYOUT_SECTION_SET_CELL_CSS_CLASS,
  payload: {
    layoutSectionId,
    cellId,
    cssClass
  }
});
export const setRowCssClass = (layoutSectionId, rowId, cssClass) => ({
  type: LAYOUT_SECTION_SET_ROW_CSS_CLASS,
  payload: {
    layoutSectionId,
    rowId,
    cssClass
  }
});
export const addClassToCell = (layoutSectionId, cellId, cssClass) => ({
  type: LAYOUT_SECTION_ADD_CLASS_TO_CELL,
  payload: {
    layoutSectionId,
    cellId,
    cssClass
  }
});
export const removeClassFromCell = (layoutSectionId, cellId, cssClass) => ({
  type: LAYOUT_SECTION_REMOVE_CLASS_FROM_CELL,
  payload: {
    layoutSectionId,
    cellId,
    cssClass
  }
});
export const setJustSavedModule = id => ({
  type: SAVED_MODULE,
  payload: {
    id
  }
});
export const updateContainers = containers => ({
  type: UPDATED_CONTAINERS_FROM_PREVIEW,
  payload: {
    containers
  }
});
export const setModuleBody = (id, body) => ({
  type: SET_MODULE_BODY,
  payload: {
    id,
    body
  }
});
export const addModulesToPostBody = (modules, html, undoData = {}, metaData = {}) => {
  undoData.groupKey = 'post_body';
  return {
    type: ADDED_MODULES_TO_POST_BODY,
    payload: {
      modules,
      html
    },
    needsResponse: true,
    metaData,
    undoData
  };
};
const getPropertyDataToUpdateForModuleInRichText = (module, reduxState) => {
  const contentTypeAsModuleSchema = selectTableAsModuleSchema(reduxState);
  const parentModuleName = module.get('parent_name');
  const parentRichTextModule = getModuleById(reduxState, parentModuleName);
  const moduleDataTokensMap = parentRichTextModule.getIn(['body', 'data_tokens']);
  if (!moduleDataTokensMap) {
    return null;
  }

  // Should only be one property as the module is inline
  const propertiesToUpdate = extractScpPropertiesFromModule({
    data_tokens: maybeToJS(moduleDataTokensMap)
  }, contentTypeAsModuleSchema);
  if (!propertiesToUpdate.length) {
    console.error('This module is not tied to any properties');
    return null;
  }
  if (propertiesToUpdate.length !== 1) {
    console.error('This module has more than one property tied to it');
    return null;
  }
  const instanceValues = selectInstanceValues(reduxState);
  // Grab html of the associated parent property
  const propertyHtml = instanceValues[propertiesToUpdate[0]];
  // Get the inline html of the associated module
  if (!propertyHtml) {
    console.error(`property for module ${parentModuleName} not found in rich text module`);
    return null;
  }
  return {
    propertyToUpdate: propertiesToUpdate[0],
    propertyHtml
  };
};
export const cloneModuleInRichText = module => (dispatch, getState) => {
  const reduxState = getState();
  const propertyData = getPropertyDataToUpdateForModuleInRichText(module, reduxState);
  if (!propertyData) {
    return;
  }
  const {
    propertyToUpdate,
    propertyHtml
  } = propertyData;
  const hublModuleElementManager = makeHublModuleElementManager(propertyHtml);
  const newModule = hublModuleElementManager.clone(module.toJS());
  const updatedPropertyHtml = hublModuleElementManager.getDOM().body.innerHTML;
  dispatch(addModulesToRichTextProperty(propertyToUpdate, [newModule], updatedPropertyHtml, {}, {
    inlineEdited: false,
    moduleMetadata: {
      id: newModule.name
    }
  }));
  dispatch(updateSelectedItem(newModule.name));
};
export const clonePostBodyModule = module => (dispatch, getState) => {
  const reduxState = getState();
  const postBodyHtml = getFakePostBodyHTML(reduxState);
  if (!postBodyHtml) {
    return;
  }
  const hublModuleElementManager = makeHublModuleElementManager(postBodyHtml);
  const newModule = hublModuleElementManager.clone(module.toJS());
  const updatedPostBodyHtml = hublModuleElementManager.getDOM().body.innerHTML;
  dispatch(addModulesToPostBody([newModule], updatedPostBodyHtml, {}, {
    inlineEdited: false
  }));
  dispatch(updateSelectedItem(newModule.name));
};
export const deleteModuleInRichText = module => (dispatch, getState) => {
  const reduxState = getState();
  const deletedModuleName = module.get('name');
  const propertyData = getPropertyDataToUpdateForModuleInRichText(module, reduxState);
  if (!propertyData) {
    return;
  }
  const {
    propertyToUpdate,
    propertyHtml
  } = propertyData;
  const hublModuleElementManager = makeHublModuleElementManager(propertyHtml);
  hublModuleElementManager.remove(deletedModuleName);
  const updatedPropertyHtml = hublModuleElementManager.getDOM().body.innerHTML;
  dispatch(clearSelectedItem({
    onlyIfSelected: deletedModuleName
  }));
  // Update modules_in_richtext and property html string
  dispatch(deleteModulesInRichTextProperty(propertyToUpdate, [module.toJS()], updatedPropertyHtml, {}, {
    inlineEdited: false
  }));
};
export const deletePostBodyModule = module => (dispatch, getState) => {
  const reduxState = getState();
  const postBodyModule = getFakePostBodyModule(reduxState);
  if (!postBodyModule) {
    return null;
  }
  const postBodyHtml = postBodyModule.getIn(['body', 'html'], '');
  const hublModuleElementManager = makeHublModuleElementManager(postBodyHtml);
  hublModuleElementManager.remove(module.get('name'));
  const updatedPostBodyHtml = hublModuleElementManager.getDOM().body.innerHTML;
  dispatch(clearSelectedItem({
    onlyIfSelected: module.get('name')
  }));
  return dispatch({
    type: UPDATE_POST_BODY,
    payload: {
      html: updatedPostBodyHtml
    },
    metaData: {
      inlineEdited: false
    }
  });
};
export const editFollowupEmail = (id, formFieldData) => ({
  type: EDIT_FORM_FOLLOWUP_EMAIL,
  payload: {
    id,
    formFieldData
  }
});

// New module has to be immutable
export const updateStaticModuleWithNewId = (id, newModule) => ({
  type: UPDATE_STATIC_MODULE_WITH_NEW_ID,
  payload: {
    id,
    newModule
  }
});
export const mergeModuleBody = (id, partialBody, {
  metaData,
  undoData = {}
}) => (dispatch, getState) => {
  const reduxState = getState();
  const module = getModuleById(reduxState, id);
  const moduleDefaultType = getDefaultType(reduxState, id);
  if (!undoData.groupKey) {
    undoData.groupKey = id;
  }
  return dispatch({
    type: MERGE_MODULE_BODY,
    payload: {
      id,
      partialBody,
      layoutSectionId: module && module.get('layout_section_id'),
      moduleDefaultType,
      metaData
    },
    undoData
  });
};

// This is used to change top level data fields that we use like "Label" on a module
export const mergeModuleData = (id, partialBody, {
  metaData,
  undoData = {}
} = {}) => (dispatch, getState) => {
  const reduxState = getState();
  const module = getModuleById(reduxState, id);
  undoData.groupKey = id;
  return dispatch({
    type: MERGE_MODULE_DATA,
    payload: {
      id,
      partialBody,
      layoutSectionId: module && module.get('layout_section_id'),
      metaData
    },
    undoData
  });
};
export const setLayoutFragmentLabel = (id, label, isRow) => ({
  type: LAYOUT_SECTION_SET_LABEL,
  payload: {
    id,
    partialBody: {
      label
    },
    isRow
  }
});
export const mergeModuleMetaData = (id, metaData) => ({
  type: MERGE_MODULE_META_DATA,
  payload: {
    id,
    metaData
  }
});
export const mergeModuleMetaDataAndIncrementEditVersion = (id, metaData) => ({
  type: MERGE_MODULE_META_DATA,
  payload: {
    id,
    metaData,
    incrementEditVersion: true
  }
});
export const setModuleSmartObjectBody = (id, body, smartObjectIndex, extraOptions = {}) => {
  const {
    updateEditVersion
  } = extraOptions;
  return {
    type: SET_MODULE_SMART_OBJECT_BODY,
    payload: {
      id,
      body,
      smartObjectIndex,
      updateEditVersion
    }
  };
};
export const mergeModuleSmartObjectBody = (id, partialBody, smartObjectIndex, extraOptions = {}) => {
  const {
    metaData,
    updateEditVersion
  } = extraOptions;
  return {
    type: MERGE_MODULE_SMART_OBJECT_BODY,
    payload: {
      id,
      metaData,
      partialBody,
      smartObjectIndex,
      updateEditVersion
    }
  };
};
export const setModuleStylesAction = (id, styles) => dispatch => {
  return dispatch({
    type: SET_MODULE_STYLES,
    payload: {
      id,
      styles
    }
  });
};
export const setModuleBreakpointStyles = (id, styles, activeBreakpoint) => ({
  type: SET_MODULE_BREAKPOINT_STYLES,
  payload: {
    id,
    styles,
    activeBreakpoint
  }
});
export const removeFakeBodyModuleStyles = () => ({
  type: REMOVE_FAKE_BODY_MODULE_STYLES
});
export const updatedModuleSmartRules = (id, smartObject) => ({
  type: UPDATED_MODULE_SMART_RULES,
  payload: {
    id,
    smartObject
  }
});
export const removedModuleSmartRules = id => ({
  type: REMOVED_MODULE_SMART_RULES,
  payload: {
    id
  }
});
export const resynchronizeModuleSmartObjects = (moduleId, smartObjects) => ({
  type: RESYNCHRONIZE_MODULE_SMART_OBJECTS,
  payload: {
    moduleId,
    smartObjects
  }
});
export const cloneModuleFromLayoutSection = (cellId, layoutSectionId) => ({
  type: LAYOUT_SECTION_CELL_CLONED_TO_RIGHT,
  payload: {
    cellId,
    layoutSectionId
  }
});
export const cloneRowFromLayoutSection = (rowId, layoutSectionId) => ({
  type: LAYOUT_SECTION_ROW_CLONED_BELOW,
  payload: {
    rowId,
    layoutSectionId
  }
});
export const cloneColumnFromLayoutSection = (cellId, layoutSectionId) => ({
  type: LAYOUT_SECTION_CELL_CLONED_TO_RIGHT,
  payload: {
    cellId,
    layoutSectionId
  }
});
export const cloneSectionFromLayoutSection = (sectionId, layoutSectionId) => ({
  type: LAYOUT_SECTION_ROW_CLONED_BELOW,
  payload: {
    rowId: sectionId,
    layoutSectionId
  }
});
const moduleHasSmartContent = (state, {
  id,
  layoutSectionId
}) => {
  const module = getCellOrRowById(state, {
    id,
    layoutSectionId
  });
  return module ? module.hasSmartContent() : false;
};
export const requestCloneFromLayoutSection = (idToClone, layoutSectionId, typeToClone, actionToDispatch) => (dispatch, getState) => {
  const hasSmartContent = moduleHasSmartContent(getState(), {
    id: idToClone,
    layoutSectionId
  });
  if (hasSmartContent) {
    return dispatch({
      type: LAYOUT_SECTION_REQUESTED_CLONE,
      payload: {
        actionToDispatch,
        typeToClone
      }
    });
  }
  return dispatch(actionToDispatch);
};
export const closeCloneRequestModal = layoutSectionId => ({
  type: CLOSE_CLONE_REQUEST_MODAL,
  payload: {
    layoutSectionId
  }
});
export const setDeprecatedStylesFlags = (id, partialBody) => ({
  type: SET_DEPRECATED_STYLES_FLAGS,
  payload: {
    id,
    partialBody
  }
});
const insertSectionIntoTreeHelper = ({
  tempCustomSectionTree,
  rowIdToMove,
  customSectionModuleSchemas,
  layoutSectionId
}) => (dispatch, getState) => {
  const currentLayoutSectionTarget = getCurrentLayoutSectionToAddTo(getState());
  const {
    rowIdToInsertAround,
    targetBeforeOrAfter,
    isEmptyLayoutSection
  } = currentLayoutSectionTarget;

  // Add the section to the layout tree
  dispatch({
    type: CUSTOM_LAYOUT_SECTION_ADDED,
    payload: {
      rowIdToInsertAround,
      targetBeforeOrAfter,
      layoutSectionId,
      isEmptyLayoutSection,
      tempCustomSectionTree,
      rowIdToMove,
      customSectionModuleSchemas
    }
  });

  // Fire action to target new section with a temporary overlay
  dispatch({
    type: LAYOUT_SECTION_ADD_TEMPORARY_OVERLAY,
    payload: {
      rowId: rowIdToMove
    },
    originalActionToPassThrough: {
      rowId: rowIdToMove,
      type: LAYOUT_SECTION_ADD_TEMPORARY_OVERLAY
    }
  });

  // Fire action to remove temporary overlay after a few seconds (it should of already completed it animation by now)
  setTimeout(() => {
    dispatch({
      type: LAYOUT_SECTION_REMOVE_TEMPORARY_OVERLAY,
      payload: {
        rowId: rowIdToMove
      },
      originalActionToPassThrough: {
        rowId: rowIdToMove,
        type: LAYOUT_SECTION_REMOVE_TEMPORARY_OVERLAY
      }
    });

    // Clear any scroll to node position info so that any updated node position bounds don't trigger
    // an auto scroll to this section anymore.
    dispatch({
      type: CLEAR_SCROLL_TO_NODE_POSITION_INFO
    });
  }, 2000);
};
export const addCustomLayoutSection = layoutData => (dispatch, getState) => {
  const state = getState();
  const isUngatedForFixedLayoutSections = getIsUngatedForFixedLayoutSections(state);
  const currentLayoutSectionTarget = getCurrentLayoutSectionToAddTo(state);
  const layoutSectionId = currentLayoutSectionTarget.layoutSectionId;
  let dataWithTree = layoutData;
  const isStaticSection = isUngatedForFixedLayoutSections && getIsStaticSectionFromValue(layoutData);
  if (isStaticSection) {
    dataWithTree = addStaticSectionLayoutDataToMockTree(layoutData);
  }
  // This util is async as it may need to make calls to endpoints to clone smart content rules
  hydrateCustomSectionIntoLayoutTree(dataWithTree, layoutSectionId, state).then(({
    tempCustomSectionTree,
    rowIdToMove,
    customSectionModuleSchemas,
    moduleSchemaErrors,
    previousTree,
    hasError = false
  }) => {
    if (hasError) {
      if (moduleSchemaErrors && moduleSchemaErrors.length && previousTree) {
        dispatch({
          type: SET_ADD_SECTION_MISSING_MODULE_ERRORS,
          payload: {
            moduleSchemaErrorCount: moduleSchemaErrors.length,
            previousTree
          }
        });
      }
      return;
    }
    dispatch(insertSectionIntoTreeHelper({
      tempCustomSectionTree,
      rowIdToMove,
      customSectionModuleSchemas,
      layoutSectionId
    }));
  }).catch(e => {
    Raven.captureException(e);
    FloatingAlertStore.addAlert({
      type: 'danger',
      titleText: I18n.text('addSectionPanel.error.modal.title'),
      message: I18n.text('addSectionPanel.error.alert.message')
    });
  });
};
export const resetLayoutSectionToTemplateDefault = id => ({
  type: RESET_LAYOUT_SECTION_TO_TEMPLATE_DEFAULT,
  payload: {
    id
  }
});
export const hydrateFailedCustomSectionTreeWithoutMissingModuleSchemas = () => (dispatch, getState) => {
  const state = getState();
  const currentLayoutSectionTarget = getCurrentLayoutSectionToAddTo(state);
  const previousInsertedSectionTree = getPreviousInsertedSectionTree(state);
  const layoutSectionId = currentLayoutSectionTarget.layoutSectionId;
  const {
    tempCustomSectionTree,
    rowIdToMove,
    customSectionModuleSchemas
  } = hydratePreviousSectionTreeIntoLayoutTreeWithoutMissingModuleSchemas(previousInsertedSectionTree, layoutSectionId, state);
  dispatch(insertSectionIntoTreeHelper({
    tempCustomSectionTree,
    rowIdToMove,
    customSectionModuleSchemas,
    layoutSectionId
  }));
};
export const updateLayoutSectionToAddTo = layoutSectionData => ({
  type: UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO,
  payload: layoutSectionData
});
export const addEmbedToEmbedInfo = (id, name) => ({
  type: ADD_NEW_EMBED_TO_EMBED_INFO,
  payload: {
    id,
    embedInfo: {
      id,
      name
    }
  }
});
export const removeEmbedFromEmbedInfo = id => ({
  type: REMOVE_EMBED_FROM_EMBED_INFO,
  payload: {
    id
  }
});
export const updateModuleBreakpointStyles = (moduleName, layoutSectionId, styles, breakpoint) => dispatch => {
  if (layoutSectionId) {
    dispatch(setBreakpointStylesForTreeNode(layoutSectionId, moduleName, styles, breakpoint));
  } else {
    dispatch(setModuleBreakpointStyles(moduleName, styles, breakpoint));
  }
};